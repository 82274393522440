<script lang="ts" setup>
import type { PropType } from 'vue'
import { Lock, User } from '@element-plus/icons-vue'
import { ElNotification } from 'element-plus'
import type {
  ConnectionType,
  GETConnection,
  UserNameAndPasswordConfig,
} from '~/services/apiClient'
import {
  ConnectionValidationStatus,
  ConnectionsApi,
} from '~/services/apiClient'
import { useConnectionsStore } from '~/stores/connections'
import { useUserStore } from '~/stores/user'

const props = defineProps({
  connectionMeta: {
    type: Object as PropType<{ label: string; type: ConnectionType }>,
    required: true,
  },
  // not required editable prop default to true
  editable: { type: Boolean, default: true },
})
const connectionStore = useConnectionsStore()
const userStore = useUserStore()
const connectionMeta = toRef(props, 'connectionMeta')
const editable = toRef(props, 'editable')
const form = reactive({
  userName: '',
  password: '',
} as any)
const savedCreds = reactive({
  userName: form.userName,
  password: form.password,
} as any)
function updateSavedCreds() {
  savedCreds.userName = form.userName
  savedCreds.password = form.password
}
const existingConnection = ref(null as null | GETConnection)
watch(connectionStore.connectionLookup, () => {
  const connection = connectionStore.connectionLookup.get(
    connectionMeta.value.type
  )
  if (connection) {
    existingConnection.value = connection
    const config = connection.config as UserNameAndPasswordConfig
    form.userName = config.user
    form.password = config.password
    updateSavedCreds()
  }
})
const enabled = ref(false)
const connectionToggleEnabled = ref(false)
watch(existingConnection, () => {
  if (existingConnection.value) {
    if (
      existingConnection.value.validation_status ===
      ConnectionValidationStatus.Disabled
    ) {
      connectionToggleEnabled.value = true
      enabled.value = false
    } else if (
      existingConnection.value.validation_status ===
      ConnectionValidationStatus.Validated
    ) {
      connectionToggleEnabled.value = true
      enabled.value = true
    } else {
      enabled.value = false
    }
  }
})
const isDirty = computed(() => {
  return JSON.stringify(savedCreds) !== JSON.stringify(form)
})

const validating = ref(false)
const toggling = ref(false)
function saveAndValidate() {
  validating.value = true
  connectionStore
    .saveAndValidate([
      {
        type: connectionMeta.value.type,
        config: {
          // It's a bummer, our client is merging all fields into one object. We could
          // fix this by making `config_type` a discriminator field in the backend but
          // that would require this field to always be passed which would be backwards
          // incompatible with older clients. So we should/could do this after this
          // code has been out for a while
          // @ts-expect-error
          config_type: 'user_name_and_password',
          user: form.userName,
          password: form.password,
        },
      },
    ])
    .then((resp) => {
      if (resp.status === 200) {
        if (
          resp.data[0].validation_status ===
          ConnectionValidationStatus.Validated
        ) {
          ElNotification.success(
            `Saved and validated ${connectionMeta.value.label} credentials`
          )
        } else if (
          resp.data[0].validation_status === ConnectionValidationStatus.Invalid
        ) {
          ElNotification.error(
            `Invalid ${connectionMeta.value.label} credentials`
          )
        } else {
          ElNotification.error(
            `Error validating ${connectionMeta.value.label} credentials`
          )
        }
      }
    })
    .finally(() => {
      validating.value = false
    })
}
function disableConnection() {
  toggling.value = true
  const api = new ConnectionsApi()
  const connectionId = existingConnection.value!.id
  api
    .deactivateConnectionsIdDeactivatePut(connectionId)
    .then((resp) => {
      if (resp.status === 200) {
        connectionStore.load()
        ElNotification.success(
          `Deactivated ${connectionMeta.value.label} connection`
        )
      } else {
        ElNotification.error(
          `Error deactivating ${connectionMeta.value.label} connection`
        )
      }
    })
    .finally(() => {
      toggling.value = false
    })
}
function disableOrEnableConnection(activated: boolean) {
  if (enabled.value) {
    disableConnection()
  } else {
    activateConnection()
  }
}

function activateConnection() {
  toggling.value = true
  const api = new ConnectionsApi()
  api
    .activateConnectionsIdActivatePut(existingConnection.value!.id)
    .then((resp) => {
      if (resp.status === 200) {
        connectionStore.load()
        ElNotification.success(
          `Activated ${connectionMeta.value.label} connection`
        )
      } else {
        ElNotification.error(
          `Error activating ${connectionMeta.value.label} connection`
        )
      }
    })
    .finally(() => {
      toggling.value = false
    })
}
</script>

<template>
  <el-form label-position="left" label-width="auto" inline autocomplete="off">
    <div class="flex items-center">
      <el-input
        v-if="!userStore.demo_mode"
        v-model="form.userName"
        :prefix-icon="User"
        class="max-w-400px mr-2"
        placeholder="Username"
        autocomplete="off"
        :disabled="!editable"
      />
      <el-input
        v-else
        :prefix-icon="User"
        class="max-w-400px"
        placeholder="Username"
      />
      <el-input
        v-model="form.password"
        :prefix-icon="Lock"
        type="password"
        placeholder="Password"
        show-password
        autocomplete="new-password"
        class="max-w-400px m-r-2"
        :disabled="!editable"
      />
      <template v-if="!validating && existingConnection">
        <div
          v-tooltip="`Credentials are ${existingConnection?.validation_status}`"
          class="mt-2"
        >
          <template
            v-if="
              existingConnection.validation_status ===
              ConnectionValidationStatus.Validated
            "
          >
            <i-akar-icons:circle-check-fill color="green" />
          </template>
          <template
            v-else-if="
              existingConnection.validation_status ===
              ConnectionValidationStatus.Invalid
            "
          >
            <i-akar-icons:circle-x color="red" />
          </template>
          <template
            v-else-if="
              existingConnection.validation_status ===
              ConnectionValidationStatus.Disabled
            "
          >
            <i-akar-icons:circle-x color="orange" />
          </template>
          <template
            v-else-if="
              existingConnection.validation_status ===
              ConnectionValidationStatus.Unvalidated
            "
          >
            <i-akar-icons:question color="grey" />
          </template>
        </div>
      </template>
      <el-button
        v-if="
          isDirty ||
          (existingConnection &&
            existingConnection.validation_status !==
              ConnectionValidationStatus.Validated &&
            existingConnection.validation_status !==
              ConnectionValidationStatus.Disabled)
        "
        type="success"
        :loading="validating"
        class="ml-2"
        @click="saveAndValidate"
      >
        <template v-if="validating">Validating...</template>
        <template v-else>Save and Validate</template>
      </el-button>
      <el-switch
        v-if="connectionToggleEnabled"
        v-tooltip="`Toggle on/off ${connectionMeta.label} connection`"
        :model-value="enabled"
        :loading="toggling"
        class="ml-2"
        size="small"
        style="--el-switch-on-color: #d3d3d3; --el-switch-off-color: orange"
        @update:model-value="disableOrEnableConnection($event as boolean)"
      />
    </div>
  </el-form>
</template>
