<script lang="ts" setup>
import { DateTime } from 'luxon'
import { useGateSchedulesStore } from '~/stores/gateSchedules'

useHead({
  title: 'Dray Dog - Gate Schedules',
})
const gateSchedulesStore = useGateSchedulesStore()

onMounted(gateSchedulesStore.load)

const thisWeeksDates = computed((): DateTime[] => {
  const startOfWeek = DateTime.now().startOf('week')
  const dates = []
  for (let i = 0; i < 7; i++) {
    dates.push(startOfWeek.plus({ days: i }))
  }
  return dates
})
const nextWeeksDates = computed((): DateTime[] => {
  const startOfWeek = DateTime.now().startOf('week')
  const dates = []
  for (let i = 7; i < 14; i++) {
    dates.push(startOfWeek.plus({ days: i }))
  }
  return dates
})

function weekTitle(dates: DateTime[]) {
  const start = dates[0].toFormat('MMMM d')
  const end = dates[dates.length - 1].toFormat('d')
  return `Week of ${start} - ${end}`
}

const now = ref(DateTime.now())
onMounted(() => {
  // Update once a minute
  setInterval(() => {
    now.value = DateTime.now()
  }, 60000)
})
</script>

<template>
  <el-scrollbar class="relative scroll-area">
    <div style="width: 1500px">
      <!-- First Week Gate Schedule -->
      <el-card>
        <h2 class="text-center text-xl font-bold">
          {{ weekTitle(thisWeeksDates) }}
        </h2>
        <div v-loading="gateSchedulesStore.loading">
          <GateScheduleTable
            :schedules="gateSchedulesStore.gate_schedules"
            :dates="thisWeeksDates"
            :now="now"
          />
        </div>
        <div class="mt-1">
          <el-alert type="info" :closable="false">
            Click an entry to view the relevant screenshot
          </el-alert>
        </div>
      </el-card>
      <!-- Next weeks gate schedules -->
      <el-card class="mt-2">
        <h2 class="text-center text-xl font-bold">
          {{ weekTitle(nextWeeksDates) }}
        </h2>
        <div v-loading="gateSchedulesStore.loading">
          <GateScheduleTable
            :schedules="gateSchedulesStore.gate_schedules"
            :dates="nextWeeksDates"
            :now="now"
          />
        </div>
        <div class="mt-1">
          <el-alert type="info" :closable="false">
            Click an entry to view the relevant screenshot
          </el-alert>
        </div>
      </el-card>
    </div>
  </el-scrollbar>
</template>

<route lang="yaml">
meta:
  layout: default
  auth: true
name: gate-schedules
</route>

<style lang="scss" scoped>
.scroll-area {
  width: calc(100vw - 170px);
}
</style>
