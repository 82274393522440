<script lang="ts" setup>
import { ElNotification } from 'element-plus'
import { useUserStore } from '~/stores/user'
import { useCurrentOrgStore } from '~/stores/currentOrg'
import type { UserInfo } from '~/models/userInfo'
import type { GETOrganization } from '~/services/apiClient'

const props = defineProps<{ userInfo: UserInfo }>()
const userStore = useUserStore()
const currentOrgStore = useCurrentOrgStore()
const router = useRouter()

const filterText = ref('')
const filterInputRef = ref<HTMLInputElement | null>(null)

const otherOrgs = computed(() => {
  const orgs = props.userInfo.organizations.filter(
    (org) => org.id !== props.userInfo.current_organization.id
  )
  orgs.sort((a, b) => a.name.localeCompare(b.name))
  return orgs
})

const filteredOrgs = computed(() => {
  if (!filterText.value) return otherOrgs.value
  const lowercaseFilter = filterText.value.toLowerCase()
  return otherOrgs.value.filter(
    (org: GETOrganization) =>
      org.name.toLowerCase().includes(lowercaseFilter) ||
      org.scac.toLowerCase().includes(lowercaseFilter) ||
      org.id.toString() === lowercaseFilter
  )
})

function logout() {
  userStore.logout()
  router.push({ name: 'login' })
}

function focusFilterInput() {
  nextTick(() => {
    filterInputRef.value?.focus()
    filterInputRef.value?.select()
  })
}

function handleVisibleChange(visible: boolean) {
  if (visible) {
    focusFilterInput()
  }
}

function handleKeyDown(event: KeyboardEvent | Event) {
  if (
    (event as KeyboardEvent).key === 'Enter' &&
    filteredOrgs.value.length > 0
  ) {
    switchToOrg(filteredOrgs.value[0])
  }
}
function switchToOrg(org: GETOrganization) {
  ElNotification.success({
    title: `Switching to ${org.name} (${org.scac})...`,
  })
  currentOrgStore.switchToOrgByScac(org.scac)
}
</script>

<template>
  <el-dropdown
    v-if="props.userInfo"
    class="ml-3"
    @visible-change="handleVisibleChange"
  >
    <div class="text-white">
      <div class="mb-1" data-testid="current-org-label">
        {{ userInfo.current_organization.name }}
        ({{ userInfo.current_organization.scac }})
      </div>
      <div class="text-xs">
        {{ userInfo.full_name }}
        <i-mdi:chevron-down class="float-right" />
      </div>
    </div>
    <template #dropdown>
      <el-dropdown-menu>
        <div style="min-width: 400px" class="px-2">
          <el-input
            v-if="otherOrgs.length > 1"
            ref="filterInputRef"
            v-model="filterText"
            placeholder="Filter organizations"
            class="my-2"
            clearable
            @keydown="handleKeyDown"
          />
          <div v-if="otherOrgs.length">
            <div class="text-sm ml-4 mt-2 text-gray-700">
              Switch organization:
            </div>
            <div style="max-height: 70vh" class="overflow-y-scroll">
              <el-dropdown-item
                v-for="org in filteredOrgs"
                :key="org.scac"
                @click="() => switchToOrg(org)"
              >
                <li class="list-disc ml-4">{{ org.name }} ({{ org.scac }})</li>
              </el-dropdown-item>
            </div>
          </div>
          <el-dropdown-item divided>
            <el-button
              v-if="userStore.loggedIn"
              type="primary"
              class="w-full"
              @click="logout"
            >
              Logout
            </el-button>
          </el-dropdown-item>
        </div>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>
