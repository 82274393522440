<script lang="ts" setup>
import Help from '../display/Help.vue'
import type { GETConnection, PortProApiConfig } from '~/services/apiClient'

const props = defineProps<{
  connection: GETConnection
}>()
const conn = toReactive(props.connection)
const tmsName = 'Port Pro'
const documentationLink = undefined
const config = reactive(conn.config as PortProApiConfig)
const formRules = undefined
</script>

<template>
  <IntegrationConnection
    :connection="props.connection"
    tms-name="Port Pro"
    :documentation-link="documentationLink"
    :form-rules="formRules"
  >
    <el-form-item label="Refresh Token:">
      <el-input
        v-model="config.refresh_token"
        style="width: 200px"
        placeholder="Refresh Token"
        show-password
      />
      <Help title="Refresh Token" class="ml-2"
        >Required to enable your {{ tmsName }} connection. It can be retrieved
        from
        <a
          href="https://app.portpro.io/settings/developer-settings"
          target="_blank"
          >here</a
        >.
        <a v-if="documentationLink" :href="documentationLink" target="_blank">
          See our help doc on this.
        </a>
      </Help>
    </el-form-item>
    <el-form-item label="Auto enter appointments:">
      <el-checkbox v-model="config.auto_enter_appointments_in_tms" />
      <Help title="Auto Enter Appointments" class="ml-2"
        >When you book or reschedule on Dray Dog, we automatically enter the
        appointment time into {{ tmsName }}. However, you can turn this on and
        off.
        <a v-if="documentationLink" :href="documentationLink" target="_blank">
          See our help doc on this.
        </a>
      </Help>
    </el-form-item>
    <el-form-item label="Auto enter empty appointments:">
      <el-checkbox
        v-model="config.auto_enter_empty_appointments_in_tms"
        v-tooltip="`Not implemented yet`"
      />
      <Help title="Auto Enter Appointments" class="ml-2"
        >When you book an empty appointment on Dray Dog, we automatically enter
        the appointment time into {{ tmsName }}. However, you can turn this on
        and off.
        <a v-if="documentationLink" :href="documentationLink" target="_blank">
          See our help doc on this.
        </a>
      </Help>
    </el-form-item>
    <el-form-item label="Send Pickup Number:">
      <el-checkbox v-model="config.send_pickup_number" />
      <Help title="Send Pickup Number" class="ml-2"
        >This option allows you to send the pickup number, the number required
        to pick up the container, instead of the standard appointment number.
        <a v-if="documentationLink" :href="documentationLink" target="_blank">
          See our help doc on this.
        </a>
      </Help>
    </el-form-item>
    <TmsTerminalCodeMapping
      :model-value="config.tms_terminal_mapping"
      tms-name="Port Pro"
      documentation-link=""
    />
  </IntegrationConnection>
</template>

<style scoped lang="scss">
.el-input {
  max-width: 400px !important;
}
</style>
