import { defineStore } from 'pinia'
import type {
  ConnectionType,
  GETConnection,
  POSTConnection,
} from '~/services/apiClient'
import { ConnectionsApi } from '~/services/apiClient'

export const useConnectionsStore = defineStore('connections', {
  state: () => {
    return {
      loading: false,
      connections: [] as GETConnection[],
      connectionLookup: new Map() as Map<ConnectionType, GETConnection>,
      loadedFromAPI: false,
    }
  },
  actions: {
    load() {
      const api = new ConnectionsApi()
      this.loading = true
      api
        .listConnectionsConnectionsGet()
        .then((resp) => {
          this.connections = resp.data
          this.connections.forEach((c) => {
            this.connectionLookup.set(c.type, c)
          })
        })
        .finally(() => {
          this.loading = false
          this.loadedFromAPI = true
        })
    },
    loadConnectionsIfNotLoaded() {
      // No Need to load if we've loaded in this same session.
      if (this.loadedFromAPI || (this.loading && !this.loadedFromAPI))
        return Promise.resolve()
      return this.load()
    },
    save(connections: POSTConnection[]) {
      const api = new ConnectionsApi()
      return api.saveConnectionsConnectionsPost(connections).then((resp) => {
        this._updateConnections(resp.data)
        return resp
      })
    },
    saveAndValidate(connections: POSTConnection[]) {
      const api = new ConnectionsApi()
      return api
        .saveAndValidateConnectionsConnectionsSaveAndValidatePost(connections)
        .then((resp) => {
          this._updateConnections(resp.data)
          return resp
        })
    },
    update(connection: GETConnection) {
      const api = new ConnectionsApi()
      return api.updateConnectionConnectionsPut(connection)
    },
    _updateConnections(connections: GETConnection[]) {
      for (const connection of connections) {
        this.connectionLookup.set(connection.type, connection)
      }
      this.connections = [...this.connectionLookup.values()]
      this.loadedFromAPI = true
    },
  },
})
