<script lang="ts" setup>
import { TERMS_AND_CONDITIONS_LINK } from '~/constants'
import { useUserStore } from '~/stores/user'
const userStore = useUserStore()
const route = useRoute()
const isSubCarrier = toRef(userStore, 'isSubCarrier')
const showBookingHistory = ref(false)
</script>

<template>
  <el-aside class="side-bar relative">
    <el-menu
      class="font-semibold menu"
      :default-active="route.path"
      :router="true"
    >
      <el-menu-item v-if="!isSubCarrier" index="/dashboard">
        <el-icon>
          <i-mdi-chart-bar />
        </el-icon>
        <template #title>Dashboard</template>
      </el-menu-item>
      <el-menu-item index="/containers">
        <el-icon>
          <LoadedContainerIcon />
        </el-icon>
        <template #title>Imports</template>
      </el-menu-item>
      <el-menu-item index="/empty_returns">
        <el-icon>
          <EmptyContainerIcon />
        </el-icon>
        <template #title>Empties</template>
      </el-menu-item>
      <el-menu-item v-if="!isSubCarrier" index="/trucks">
        <el-icon>
          <TruckIcon />
        </el-icon>
        <template #title>Trucks</template>
      </el-menu-item>
      <el-menu-item v-if="!isSubCarrier" index="/capacity">
        <el-icon>
          <CapacityIcon />
        </el-icon>
        <template #title>Capacity</template>
      </el-menu-item>
      <el-menu-item index="/gate_schedules">
        <el-icon>
          <i-carbon:event-schedule class="" />
        </el-icon>
        <template #title>Gates</template>
      </el-menu-item>
      <el-menu-item @click="showBookingHistory = !showBookingHistory">
        <el-icon>
          <i-mdi:history />
        </el-icon>
        <template #title>History</template>
      </el-menu-item>
      <!-- <el-menu-item index="book-appointments">
        <el-icon>
          <i-mdi-calendar-plus />
        </el-icon>
        <template #title> Book Appointments </template>
      </el-menu-item> -->
      <el-menu-item v-if="!isSubCarrier" index="/settings">
        <el-icon>
          <i-mdi-cog />
        </el-icon>
        <template #title> Settings </template>
      </el-menu-item>
      <el-menu-item>
        <a href="https://help.draydog.com" target="_blank">
          <el-icon>
            <i-ic:baseline-help-outline />
          </el-icon>
          Help
        </a>
      </el-menu-item>
    </el-menu>
    <div>
      <a
        :href="TERMS_AND_CONDITIONS_LINK"
        target="_blank"
        class="absolute bottom-2 text-xs text-gray-400 mx-auto w-full text-center"
      >
        Terms and Conditions
      </a>
    </div>
  </el-aside>
  <ActionHistoryDrawer
    v-if="userStore.loggedIn && showBookingHistory"
    v-model="showBookingHistory"
  />
</template>

<style scoped>
.menu:not(.el-menu--collapse) {
  min-height: 100%;
}

.side-bar {
  box-shadow: var(--el-box-shadow-light);
  --menu-width: 130px;
}

aside {
  max-width: var(--menu-width);
}

.el-menu {
  width: calc(var(--menu-width) - 1) !important;
}
.el-menu-item {
  padding: 0px 12px !important;
}
</style>
