import type { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { useReturnRulesAndFilters } from './useReturnRulesAndFilters'
import type { Shift, TerminalName } from '~/services/apiClient'
import { TransactionDirection } from '~/services/apiClient'
import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'
import { useAppointmentWithContainerInfoStore } from '~/stores/appointmentsWithContainerInfo'

import { returnOptionKey } from '~/models/returnRules'

const useAppointmentsForReturnRules = createSharedComposable(
  useAppointmentsForReturnRulesInner
)
export default useAppointmentsForReturnRules

function useAppointmentsForReturnRulesInner() {
  // Stores
  const appointmentsStore = useAppointmentWithContainerInfoStore()

  // Compositions
  const returnRulesAndFilters = useReturnRulesAndFilters()
  const { shippingLine, containerType, applicableTerminals } =
    returnRulesAndFilters

  // Refs
  const { appointments } = storeToRefs(appointmentsStore)
  const existingSingleEmptyAppointments = ref<AppointmentWithContainerInfo[]>(
    []
  )
  const existingDualAppointments = ref<AppointmentWithContainerInfo[]>([])
  const existingDualAppointmentsLookup = ref<
    Map<string, AppointmentWithContainerInfo[]>
  >(new Map())
  const dualAppointmentOpportunities = ref<AppointmentWithContainerInfo[]>([])
  const dualAppointmentOpportunitiesLookup = ref(
    new Map<string, AppointmentWithContainerInfo[]>()
  )
  const numDualOpportunities = ref(0)

  //  Watches
  watch(
    [appointments, shippingLine, containerType, applicableTerminals],
    categorizeAppointments
  )

  // Functions
  function categorizeAppointments() {
    const singles: AppointmentWithContainerInfo[] = []
    const dualOpportunities: AppointmentWithContainerInfo[] = []
    const dualOpportunitiesLookup: Map<string, AppointmentWithContainerInfo[]> =
      new Map()
    const usedDuals: AppointmentWithContainerInfo[] = []
    const usedDualsLookup: Map<string, AppointmentWithContainerInfo[]> =
      new Map()
    if (shippingLine.value && containerType.value) {
      for (const apptInfo of appointmentsStore.appointments) {
        if (!applicableTerminals.value.has(apptInfo.appointment.terminal)) {
          continue
        }
        if (
          apptInfo.appointment.direction === TransactionDirection.Inbound &&
          apptInfo.appointment.loaded === false
        ) {
          if (apptInfo.watch.shipping_line !== shippingLine.value) continue
          if (apptInfo.watch.container_type !== containerType.value) continue
          if (apptInfo.appointment.linked_appointments.length === 0) {
            singles.push(apptInfo)
          } else {
            usedDuals.push(apptInfo)
            const key = returnOptionKeyFromAppointmentInfo(apptInfo)
            if (key) {
              if (usedDualsLookup.has(key)) {
                usedDualsLookup.get(key)?.push(apptInfo)
              } else {
                usedDualsLookup.set(key, [apptInfo])
              }
            }
          }
        } else if (
          apptInfo.appointment.direction === TransactionDirection.Outbound &&
          apptInfo.appointment.loaded === true
        ) {
          if (!appointmentCanDual(apptInfo)) continue
          if (apptInfo.appointment.linked_appointments.length === 0) {
            const key = returnOptionKeyFromAppointmentInfo(apptInfo)
            // Key would be null if the appointment's container didn't have a line or type
            if (key) {
              dualOpportunities.push(apptInfo)
              const opportunities = dualOpportunitiesLookup.get(key)
              if (opportunities) {
                opportunities.push(apptInfo)
              } else {
                dualOpportunitiesLookup.set(key, [apptInfo])
              }
            }
          } else {
            // TODO store/link the import half of duals?
          }
        }
      }
    }
    existingSingleEmptyAppointments.value = singles
    existingDualAppointments.value = usedDuals
    existingDualAppointmentsLookup.value = usedDualsLookup
    dualAppointmentOpportunities.value = dualOpportunities
    dualAppointmentOpportunitiesLookup.value = dualOpportunitiesLookup
    numDualOpportunities.value = dualAppointmentOpportunities.value.length
  }
  function appointmentCanDual(apptInfo: AppointmentWithContainerInfo): boolean {
    // Appointments already dualed are not "dual eligible"
    if (apptInfo.appointment.linked_appointments.length > 0) return false
    return returnRulesAndFilters.isAppointmentDualEligible(apptInfo)
  }
  function getDualOpportunitiesForShift(
    terminal: TerminalName,
    shiftDate: DateTime,
    shift: Shift
  ): AppointmentWithContainerInfo[] {
    const key = returnOptionKey({
      shiftDate,
      shift,
      terminal,
    })
    return dualAppointmentOpportunitiesLookup.value.get(key) ?? []
  }
  return {
    existingSingleEmptyAppointments,
    existingDualAppointments,
    existingDualAppointmentsLookup,
    dualAppointmentOpportunities,
    numDualOpportunities,
    // Functions
    getDualOpportunitiesForShift,
  }
}

function returnOptionKeyFromAppointmentInfo(
  apptInfo: AppointmentWithContainerInfo
): string | null {
  if (apptInfo.watch.shipping_line && apptInfo.watch.container_type) {
    return returnOptionKey({
      shiftDate: apptInfo.appointment.shiftDate,
      shift: apptInfo.appointment.shift,
      terminal: apptInfo.appointment.terminal,
    })
  } else {
    return null
  }
}
