/* eslint-disable no-console */
import { ElNotification } from 'element-plus'
import type { SoketiEvent } from './configureSoketi'
import { useSoketi } from '#compositions/configureSoketi'
import type {
  AppointmentsCheckedNotification,
  GETCapacitySet,
  GETContainer,
  GETTruck,
  GlobalNotification,
  GlobalNotificationEvent,
  ImportStatusCheckedNotification,
  Notification,
  OrganizationNotificationEvent,
  OrganizationUpdated,
  UserNotificationEvent,
} from '~/services/apiClient'
import {
  EventName,
  NotificationType,
  NotificationsApi,
  WebsocketChannel,
} from '~/services/apiClient'
import { useUserStore } from '~/stores/user'
import { useContainerStore } from '~/stores/containers'
import { useCapacitiesStore } from '~/stores/capacities'
import { useGlobalNotificationsStore } from '~/stores/globalNotifications'
import { useAppointmentWithContainerInfoStore } from '~/stores/appointmentsWithContainerInfo'
import { useTrucksStore } from '~/stores/trucks'

export function useNotificationsForLoggedInUser() {
  const userStore = useUserStore()
  const capacitiesStore = useCapacitiesStore()
  const trucksStore = useTrucksStore()
  const soketi = useSoketi()
  // clear seen messages every hour
  const seenMessages = new Set<string>()
  setInterval(
    () => {
      seenMessages.clear()
    },
    1000 * 60 * 60
  )
  const globalNotificationsStore = useGlobalNotificationsStore()
  const containerStore = useContainerStore()
  const appointmentsWithContainerInfoStore =
    useAppointmentWithContainerInfoStore()
  const authHeader = toRef(userStore, 'authHeader')
  const connected = ref(false)
  function fetchActiveGlobalNotifications() {
    const notificationsApi = new NotificationsApi()
    notificationsApi
      .getActiveGlobalNotificationsNotificationsGlobalGet()
      .then((response) => {
        response.data.forEach((notification) => {
          const globalNotification = notification.object as GlobalNotification
          globalNotificationsStore.update(globalNotification)
        })
      })
  }
  function connect() {
    if (userStore.loggedIn) {
      userStore.loadInfoIfNeeded().then(() => {
        soketi.bind(
          WebsocketChannel.PrivateCacheGlobalNotifications,
          EventName.GlobalNotification,
          handleSoketiMessage
        )
        if (!userStore.orgNotificationsChannel) {
          console.error('No org notifications channel')
          return
        }
        console.log(
          'Binding to org notifications channel ' +
            userStore.orgNotificationsChannel
        )
        soketi.bind(
          userStore.orgNotificationsChannel,
          EventName.OrganizationNotification,
          handleSoketiMessage
        )

        if (!userStore.userNotificationsChannel) {
          console.error('No user notifications channel')
          return
        }
        console.log(
          'Binding to user notifications channel ' +
            userStore.userNotificationsChannel
        )
        soketi.bind(
          userStore.userNotificationsChannel,
          EventName.UserNotification,
          handleSoketiMessage
        )
      })
    }
  }

  function disconnect() {
    soketi.unbind(
      WebsocketChannel.PrivateCacheGlobalNotifications,
      EventName.GlobalNotification,
      handleSoketiMessage
    )
    // NOTE: unbinding from org and user notifications is handled in userStore
    // should we unbind from all channels here?
  }
  onMounted(() => {
    if (userStore.loggedIn) {
      fetchActiveGlobalNotifications()
      connect()
    }
  })
  onUnmounted(disconnect)
  watch(authHeader, () => {
    disconnect()
    if (userStore.loggedIn) {
      connect()
    }
  })
  function handleSoketiMessage(event: SoketiEvent) {
    if (seenMessages.has(event.message_id)) {
      console.log('Ignoring duplicate message for ', event.message_id)
      return
    }
    seenMessages.add(event.message_id)
    const receivedEvent = event as
      | OrganizationNotificationEvent
      | UserNotificationEvent
      | GlobalNotificationEvent
    const message = receivedEvent.notification as Notification
    if (message.title) {
      ElNotification({
        title: message.title,
        message: message.message,
        type: message.level,
        duration: 15000,
      })
    }
    switch (message.event_type) {
      case NotificationType.ContainerUpdated:
        containerStore.updateContainer(message.object as GETContainer)
        appointmentsWithContainerInfoStore.updateFromContainer(
          message.object as GETContainer
        )
        break
      case NotificationType.ImportStatusesChecked:
        containerStore.updateImportStatusLastObservedTimes(
          message.object as ImportStatusCheckedNotification
        )
        break
      case NotificationType.AppointmentsChecked:
        containerStore.updateAppointmentLastObservedTimes(
          message.object as AppointmentsCheckedNotification
        )
        break
      case NotificationType.CapacitySetUpdated:
        capacitiesStore.setCapacitySetsFromAPI([
          message.object as GETCapacitySet,
        ])
        break
      case NotificationType.GlobalNotification: {
        const globalNotification = message.object as GlobalNotification
        globalNotificationsStore.update(globalNotification)
        break
      }
      case NotificationType.OrganizationUpdated: {
        userStore.updateOrg(
          (message.object as OrganizationUpdated).organization
        )
        break
      }
      case NotificationType.TrucksUpdated: {
        trucksStore.addTrucksToStore([message.object as GETTruck])
        break
      }
      default:
        console.warn('Unhandled notification type', message.event_type)
    }
  }
  return { connected }
}
