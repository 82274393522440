<script lang="ts" setup>
import { DateTime } from 'luxon'
import type { BookEvent } from './events'
import { useApptAvailabilityStore } from '~/stores/appointmentSlots'
import { useLuxonTimeAgo } from '~/compositions/timeAgo'
import type { Container } from '~/models/containers'
import { useUserStore } from '~/stores/user'
import { useRemainingCapacitiesStore } from '~/stores/remainingCapacities'
import type { AppointmentSlot } from '~/models/appointmentSlot'
import { TerminalName, TransactionDirection } from '~/services/apiClient'
import { useAppointmentCountStoreForAvailability } from '~/stores/appointmentCounts'

const props = defineProps<{ container: Container }>()

provide('container', props.container)

// Stores
const apptAvailabilityStore = useApptAvailabilityStore()
const remainingCapacitiesStore = useRemainingCapacitiesStore()
const userStore = useUserStore()
const apptCountStore = useAppointmentCountStoreForAvailability()

// Refs
const loadAvailabilityErrorMessage = ref('')
const slotsFetchedTime = toRef(apptAvailabilityStore, 'slotsObserved')
const bookingSlot = ref<AppointmentSlot | null>(null)

// Computed properties
const slotsAgeDesc = useLuxonTimeAgo(slotsFetchedTime)
const existingAppointment = computed(() => {
  return props.container.booked_appointment
})

// Methods
function loadAppointmentSlots(options: { forceRefresh: boolean }) {
  loadAvailabilityErrorMessage.value = ''
  const promise = apptAvailabilityStore.load(props.container.number, options)
  promise.then((errorMessage) => {
    if (errorMessage) loadAvailabilityErrorMessage.value = errorMessage

    if (
      !options.forceRefresh &&
      slotsFetchedTime.value &&
      slotsFetchedTime.value < DateTime.now().minus({ minutes: 2 })
    ) {
      // Start a "background" refresh where we let user use the current times but start
      // loading new times as well
      apptAvailabilityStore.load(props.container.number, {
        forceRefresh: true,
        background: true,
      })
    }
  })
}
const suggestAutoBookOnFailure = computed(() => {
  return !props.container.booked_appointment
})
onMounted(() => {
  loadAppointmentSlots({ forceRefresh: false })
  // Sub-carriers cannot see capacity
  if (!userStore.isSubCarrier) {
    // It would be nice to pass the time period to load based on when there are slots
    // available but then we would have to wait for slots to load which is suboptimal
    // and would mean we would display slots without capacity color coding for a bit
    let daysToLoad = 7
    if (props.container.terminal === TerminalName.Fms) {
      daysToLoad = 9 // FMS will post appointments further out than other terminals
    }
    remainingCapacitiesStore.load(
      props.container.customer_id ?? null,
      props.container.tags,
      daysToLoad
    )
    apptCountStore.load({
      time_bucket_hours: 3,
      after: DateTime.now().minus({ hours: 3 }),
      direction: TransactionDirection.Outbound,
      loaded: true,
    })
  }
})
function fetchedAgoTextColor(fetchedAgoTime: DateTime | null): string {
  if (!fetchedAgoTime) return 'text-gray-500'
  const minutes = Math.abs(fetchedAgoTime.diffNow('minutes').minutes)
  if (minutes < 1) return 'text-gray-500'
  if (minutes < 2) return 'text-orange-600'
  return 'text-red-500'
}
function openBookingDialog({ slot }: BookEvent) {
  bookingSlot.value = slot as AppointmentSlot
}
</script>

<template>
  <div
    v-if="
      !apptAvailabilityStore.failed &&
      (apptAvailabilityStore.loading ||
        (apptAvailabilityStore.slots.length > 0 &&
          apptAvailabilityStore.availabilityFound))
    "
  >
    <div class="box pr-2 min-h-14 relative w-full">
      <div class="absolute text-right right-2"></div>

      <div class="absolute text-right right-2" style="padding-top: 8px">
        <div class="flex items-center gap-2">
          <ShowHideAppointmentsButton class="custom-margin-bottom">
          </ShowHideAppointmentsButton>
          <RefreshButton
            style="margin-bottom: 4px"
            :loading="apptAvailabilityStore.loading"
            @click="
              loadAppointmentSlots({
                forceRefresh: true,
              })
            "
          />
        </div>
        <h3
          v-if="apptAvailabilityStore.slotsObserved"
          v-tooltip="`Fetched at ${slotsFetchedTime!.toFormat('H:mm')}`"
          :class="[fetchedAgoTextColor(slotsFetchedTime)]"
          class="text-xs"
        >
          Fetched {{ slotsAgeDesc }}
        </h3>
      </div>
      <h2
        class="text-left mt-2 mb-4 font-lg font-semibold"
        style="padding-left: 32px; padding-bottom: 20px"
      >
        Available Appointment Times
      </h2>

      <AppointmentSlotsDisplay
        :loading="apptAvailabilityStore.loading"
        :slots="apptAvailabilityStore.slots"
        @book="openBookingDialog"
      />
    </div>
  </div>
  <div v-else class="font-md bg-gray-100 rounded py-2 px-4 border">
    <div class="flex justify-between items-center">
      <div>
        <template v-if="apptAvailabilityStore.failed">
          Loading availability failed.
          <el-button @click="loadAppointmentSlots({ forceRefresh: true })"
            >Try Again</el-button
          >
          <template v-if="suggestAutoBookOnFailure">
            or try auto booking
          </template>
        </template>
        <template v-else-if="!apptAvailabilityStore.availabilityFound">
          We weren't able to check availability for this container 😔
        </template>
        <template v-else>
          <i-mdi:alert class="align-middle" />
          No appointments available.
          <RefreshButton
            :loading="apptAvailabilityStore.loading"
            size="default"
            @click="
              loadAppointmentSlots({
                forceRefresh: true,
              })
            "
          />
        </template>
      </div>
      <div>
        <AutoBookButton
          v-if="suggestAutoBookOnFailure"
          :containers="[container]"
        />
      </div>
    </div>
    <div
      v-if="loadAvailabilityErrorMessage"
      class="text-xs text-gray-700 mt-2 opacity-80"
    >
      <strong class="text-red-800">Error Message:</strong>
      {{ loadAvailabilityErrorMessage }}
    </div>
  </div>
  <BookingDialog
    v-if="bookingSlot && container"
    :appointment-slot="bookingSlot"
    :existing-appointment="existingAppointment"
    :container="container"
    @close="bookingSlot = null"
  />
</template>

<style lang="scss">
.box {
  @apply bg-gray-50 rounded border py-2;
}

.custom-margin-bottom {
  margin-bottom: 5px;
}
</style>
