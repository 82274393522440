<script lang="ts" setup>
import type { BookEvent } from './events'
import type { Appointment } from '~/models/appointments'
import type { BaseAppointmentSlot } from '~/models/appointmentSlot'
import { useRemainingCapacitiesStore } from '~/stores/remainingCapacities'

const props = withDefaults(
  defineProps<{
    availabilitySlot: BaseAppointmentSlot
    bookedAppointment: Appointment | null
    size?: 'small' | 'default' | 'large'
    showTime?: boolean
    showCapacity?: boolean
  }>(),
  { showTime: true, showCapacity: true }
)

const emit = defineEmits({
  book: (event: BookEvent) => true,
})
const slot = toRef(props, 'availabilitySlot')

const remainingCapacitiesStore = useRemainingCapacitiesStore()
const remainingCapacity = computed(() => {
  if (!props.showCapacity || !remainingCapacitiesStore.loaded) return null
  return remainingCapacitiesStore.getRemainingCapacityForTime(
    slot.value.window_start
  )
})

const buttonType = computed(
  (): 'danger' | 'warning' | 'info' | 'primary' | 'success' => {
    if (slot.value.booked_appointment) {
      return 'danger'
    } else if (slot.value.num_appointments_available === 0) {
      return 'info'
    } else {
      if (props.showCapacity && remainingCapacity.value !== null) {
        if (remainingCapacity.value > 0) {
          return 'success'
        } else {
          return 'danger'
        }
      }
      return 'primary'
    }
  }
)
</script>

<template>
  <div>
    <!-- Cancel -->
    <CancelAppointmentButton
      v-if="slot.booked_appointment"
      :appointment="slot.booked_appointment"
      class="w-full"
    >
      <el-button
        plain
        :type="buttonType"
        class="w-full m-1 fs-open-booking-dialog"
        :size="size"
        style="margin-left: 0px"
      >
        <div>
          <div>
            Cancel
            {{ slot.window_start.toFormat('H:mm') }} -
            <template v-if="slot.window_end">
              {{ slot.window_end.toFormat('H:mm') }}
            </template>
          </div>
          <RemainingCapacityDescription
            v-if="remainingCapacity !== null"
            :remaining-capacity="remainingCapacity"
            :show-icon="false"
          />
        </div>
      </el-button>
    </CancelAppointmentButton>
    <!-- Book or Reschedule -->
    <el-button
      v-else
      plain
      class="w-full m-1"
      :size="size"
      style="margin-left: 0px"
      :disabled="slot.num_appointments_available === 0"
      :type="buttonType"
      @click="
        emit('book', {
          slot,
        })
      "
    >
      <div>
        <!-- Button text -->
        <div>
          <template v-if="bookedAppointment">Reschedule to </template>
          <template v-else>Book </template>
          <template v-if="showTime">
            {{ slot.window_start.toFormat('H:mm') }} -
            <template v-if="slot.window_end">
              {{ slot.window_end.toFormat('H:mm') }}
            </template>
          </template>
          (<template v-if="slot.num_appointments_available !== null">{{
            slot.num_appointments_available
          }}</template
          ><template v-else>1+</template> avail)
        </div>
        <!-- Capacity warning -->
        <RemainingCapacityDescription
          v-if="remainingCapacity !== null"
          :remaining-capacity="remainingCapacity"
        />
      </div>
    </el-button>
  </div>
</template>
