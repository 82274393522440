<script lang="ts" setup>
import type {
  CompcareCoreAPIConfig,
  ProfitToolsCoreAPIConfig,
} from '~/services/apiClient'

const { config, documentationLink, tmsName } = defineProps<{
  config: ProfitToolsCoreAPIConfig | CompcareCoreAPIConfig
  documentationLink: string | undefined
  tmsName: string
}>()
// update the connection config when changed
const emit = defineEmits<{
  (
    e: 'update:config',
    value: ProfitToolsCoreAPIConfig | CompcareCoreAPIConfig
  ): void
}>()

const editConfig = toReactive(config)
</script>

<template>
  <el-form-item label="Auto enter appointments:">
    <el-checkbox v-model="editConfig.auto_enter_appointments_in_tms" />
    <Help title="Auto Enter Appointments" class="ml-2"
      >When you book or reschedule on Dray Dog, we automatically enter the
      appointment time into {{ tmsName }}. However, you can turn this on and
      off.
      <a v-if="documentationLink" :href="documentationLink" target="_blank">
        See our help doc on this.
      </a>
    </Help>
  </el-form-item>
  <el-form-item label="Auto enter empty appointments:">
    <el-checkbox v-model="editConfig.auto_enter_empty_appointments_in_tms" />
    <Help title="Auto Enter Appointments" class="ml-2"
      >When you book an empty appointment on Dray Dog, we automatically enter
      the appointment time into {{ tmsName }}. However, you can turn this on and
      off.
      <a v-if="documentationLink" :href="documentationLink" target="_blank">
        See our help doc on this.
      </a>
    </Help>
  </el-form-item>
  <el-form-item label="Set empty appointment on leg start:">
    <el-checkbox v-model="editConfig.set_empty_appointment_time_on_leg_start" />
    <Help title="Set Empty Appointment on Leg Start" class="ml-2"
      >This tells us to set the empty appointment time on the hook-empty or
      mount-empty event instead of the drop-empty or dismount-empty event.
      <a v-if="documentationLink" :href="documentationLink" target="_blank">
        See our help doc on this.
      </a>
    </Help>
  </el-form-item>
  <el-form-item label="Send Pickup Number:">
    <el-checkbox v-model="editConfig.send_pickup_number" />
    <Help title="Send Pickup Number" class="ml-2"
      >This option allows you to send the pickup number, the number required to
      pick up the container, instead of the standard appointment number.
      <a v-if="documentationLink" :href="documentationLink" target="_blank">
        See our help doc on this.
      </a>
    </Help>
  </el-form-item>
  <el-form-item label="TMS Timezone:" label-width="auto">
    <el-input v-model="editConfig.timezone" :disabled="true" />
    <Help title="Timezone" class="ml-2"
      >This is the timezone your TMS is configured in.
      <a v-if="documentationLink" :href="documentationLink" target="_blank">
        See our help doc on this.
      </a>
    </Help>
  </el-form-item>
  <TmsTerminalCodeMapping
    :model-value="config.tms_terminal_mapping"
    tms-name="Profit Tools"
    :documentation-link="documentationLink"
  />
</template>
