<script lang="ts" setup>
import type { BookEvent } from '../appointmentSlots/events'
import type { TimeSlot } from './types'
import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'

const props = defineProps<{
  timeSlot: TimeSlot
}>()
const emit = defineEmits({
  book: (event: BookEvent) => true,
  addEmpty: (opportunity: AppointmentWithContainerInfo) => true,
})
const existingSingleEmptyAppointments = computed(() => {
  return props.timeSlot.existingSingleEmptyAppointments
})
const existingDualEmptyAppointments = computed(() => {
  return props.timeSlot.existingDualEmptyAppointments
})
</script>

<template>
  <tr>
    <td class="time-label">
      <div>
        {{ timeSlot.window_start.toFormat('H:mm')
        }}<template v-if="timeSlot.window_end">
          - {{ timeSlot.window_end.toFormat('H:mm') }}</template
        >:
      </div>
    </td>
    <!-- Available single appointments -->
    <td class="px-1">
      <AppointmentSlotButton
        v-if="props.timeSlot.slot"
        :availability-slot="props.timeSlot.slot"
        :booked-appointment="null"
        :show-time="false"
        :show-capacity="false"
        size="default"
        class="w-full"
        @book="emit('book', $event)"
      />
      <el-button v-else disabled class="my-1 w-full">
        <span class="text-red-800 opacity-40">none available</span>
      </el-button>
    </td>
    <!-- Dual opportunities (imports without an empty) -->
    <td>
      <DualOpportunitiesDropdown
        v-if="props.timeSlot.dualOpportunities.length > 0"
        :dual-opportunities="props.timeSlot.dualOpportunities"
        class="mt-1"
        @add-empty="emit('addEmpty', $event)"
      />
    </td>
    <!-- Existing appointments -->
    <td class="text-xs">
      <BookedEmptiesDropdown
        :appointments-with-containers="existingSingleEmptyAppointments"
        :show-terminal="false"
        :show-cancel-button="true"
      />
    </td>
    <td class="text-xs">
      <BookedEmptiesDropdown
        :appointments-with-containers="existingDualEmptyAppointments"
        :show-terminal="true"
        :show-cancel-button="false"
      />
    </td>
  </tr>
</template>

<style scoped lang="scss">
.time-label {
  @apply text-sm text-left text-black  text-gray-600 pr-2;
  div {
    @apply flex items-center h-full justify-end;
  }
}
</style>
