<script lang="ts" setup>
import type { UploadInstance, UploadUserFile } from 'element-plus'
import { ElMessage, ElNotification, ElUpload } from 'element-plus'
import { useContainerFilters } from '~/compositions/containerFilters'
import type {
  ContainerUploadError,
  POSTUploadContainersResponse,
} from '~/services/apiClient'
import { useContainerStore } from '~/stores/containers'
import { useUserStore } from '~/stores/user'
import { useUsersStore } from '~/stores/users'
import { parseContainerNumbers } from '~/utils'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'close'): void
}>()

const activeTabName = ref('paste')

const dialogOpen = useVModel(props, 'modelValue', emit)
const containersStore = useContainerStore()
const userStore = useUserStore()
const usersStore = useUsersStore()
const { containerSearch } = useContainerFilters()
const showUploadInfo = ref(false)

const assignedUserIDs = ref([] as number[])
const customerID = ref(undefined as number | undefined)
const fileList = ref<UploadUserFile[]>([])
const fileUploadRef = ref<UploadInstance>()
const pastedContainersText = ref('')
const parsedContainerNumbers = ref([] as string[])
const tagsToAdd: Ref<string[]> = ref([])
const readyForUpload = computed(() => {
  return parsedContainerNumbers.value.length > 0 || fileList.value.length > 0
})
const errorContainers = ref([] as ContainerUploadError[])
watch([pastedContainersText], () => {
  parsedContainerNumbers.value = parseContainerNumbers(
    pastedContainersText.value
  )
})
function onError(e: Error) {
  ElNotification.error('Containers upload failed: ' + e.toString())
}
function onExceed() {
  ElMessage.error(
    'You can only upload one file at a time. Remove your existing file to add a new one'
  )
}
function closeDialog() {
  dialogOpen.value = false
}
function onSuccess(response: POSTUploadContainersResponse) {
  if (response.container_numbers.length > 0) {
    ElNotification.success({
      title: `Uploaded ${response.container_numbers.length} containers`,
    })
    containerSearch.value = response.container_numbers.join(',')
  }
  if (response.errors.length > 0) {
    errorContainers.value = response.errors
    ElMessage.error({
      message: `Failed to upload ${response.errors.length} containers`,
      duration: 10000,
    })
  } else {
    closeDialog()
  }
  fileList.value = []
}
const uploadURL = computed(() => {
  let url = 'https://api.draydog.com/containers/watch/via_upload/?'
  if (assignedUserIDs.value.length) {
    for (const userID of assignedUserIDs.value) {
      url += `assign_to_user_id=${userID}&`
    }
  }
  if (customerID.value) {
    url += `customer_id=${customerID.value}`
  }
  return url
})
function upload() {
  if (!readyForUpload.value) {
    ElMessage.error('Please paste some container numbers or select a file')
    return
  }
  errorContainers.value = []
  if (fileList.value.length > 0) {
    fileUploadRef.value!.submit()
  } else if (parsedContainerNumbers) {
    const watches = parsedContainerNumbers.value.map((containerNumber) => {
      return {
        container_number: containerNumber,
        customer_id: customerID.value,
        tags: tagsToAdd.value,
      }
    })
    containersStore
      .watchContainers(
        watches,
        usersStore.users.filter((user) =>
          assignedUserIDs.value.includes(user.id)
        ),
        customerID.value
      )
      .then((response) => {
        if (response.data.container_numbers.length > 0) {
          ElNotification.success({
            title: `Uploaded ${response.data.container_numbers.length} containers`,
          })
          containerSearch.value = response.data.container_numbers.join(',')
        }
        if (response.data.errors.length > 0) {
          errorContainers.value = response.data.errors
          ElMessage.error({
            message: `Failed to upload ${response.data.errors.length} containers`,
            duration: 10000,
          })
        } else {
          closeDialog()
        }
      })
  } else {
    // Should never get here, but just in case
    ElMessage.warning('Please paste some container numbers or upload a file')
  }
}
</script>

<template>
  <BaseDialog
    v-if="dialogOpen"
    v-model="dialogOpen"
    class="px-2"
    @close="emit('close')"
  >
    <template #header>
      <h1 class="color-text-darkblue">
        <span class="text-xl font-bold">Add Containers</span>
        <Help title="Uploading Containers" class="inline-block ml-1 mb-1">
          <p>
            You may upload the same containers as many times as you like,
            containers will not be duplicated. If you re-upload containers with
            new "container information," the containers will be updated.
          </p>
        </Help>
      </h1>
    </template>

    <template #default>
      <p class="color-text-darkblue font-medium text-lg -mt-6">
        Upload or paste container(s) information
      </p>
      <el-tabs v-model="activeTabName" class="demo-tabs mt-4">
        <el-tab-pane label="Paste containers" name="paste" class="mt-4">
          <el-input
            v-model="pastedContainersText"
            :rows="5"
            type="textarea"
            placeholder="OOCU7761569, MEDU7677697, etc."
          />
        </el-tab-pane>
        <el-tab-pane label="Upload spreadsheet" name="upload">
          <ElUpload
            ref="fileUploadRef"
            v-model:file-list="fileList"
            accept=".xlsx,.csv"
            :action="uploadURL"
            drag
            :multiple="false"
            :limit="1"
            :on-error="onError"
            :on-success="onSuccess"
            :on-exceed="onExceed"
            :headers="{ Authorization: userStore.authHeader }"
            :auto-upload="false"
          >
            <el-icon class="el-icon--upload"><i-mdi:upload /></el-icon>
            <div class="el-upload__text">
              Drop .xlsx or .csv file here or <em>click to select a file</em>
            </div>
          </ElUpload>

          <el-button
            link
            class="mt-2"
            @click="showUploadInfo = !showUploadInfo"
          >
            <i-mdi:information class="align-middle mr-1" />Show upload
            instructions
            <i-mdi:chevron-down
              class="ml-1 ease-in duration-300"
              :class="{ 'custom-rotate-180': showUploadInfo }"
          /></el-button>
          <div v-if="showUploadInfo" class="ease-in duration-300">
            <div type="info" show-icon effect="dark" :closable="false">
              <li>
                Document must be .xlsx or .csv and have a header row<br /><b
                  >One column header must contain "container"
                  (case-insensitive)</b
                >.
              </li>
              <li>
                You may include a
                <b>"master bill of lading", "b/l", or "mbol"</b> column to set
                the bill of lading for the container.
              </li>
              <li>
                You may include a <b>"customer" or "customer name"</b> column to
                upload with the containers. The container will be auto assigned
                to the existing customer or a new customer will be created if
                the customer doesn't exist.
              </li>
              <li>
                You may include an <b>"overweight"</b> column and rows that have
                a value of "yes" or "y" will be tagged as "Overweight"
              </li>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <hr class="mt-8 mb-4" />

      <p class="color-text-darkblue font-medium text-lg">
        Set container information (optional)
      </p>
      <UsersCustomerAndTagsSelector
        v-model:assignedUserIDs="assignedUserIDs"
        v-model:customerID="customerID"
        v-model:tagsToAdd="tagsToAdd"
        class="mt-4"
      />
      <div>
        <ul class="text-red-700">
          <strong v-if="errorContainers.length > 0">Errors:</strong>
          <li
            v-for="errorContainer in errorContainers"
            :key="errorContainer.container_number"
          >
            <b>
              {{ errorContainer.container_number }} -
              {{ errorContainer.message }}</b
            >
          </li>
        </ul>
      </div>
    </template>

    <template #action-button>
      <el-button
        v-tooltip="{
          content: 'Please paste some container numbers or select a file',
          disabled: readyForUpload,
        }"
        :loading="containersStore.executingWatchContainers"
        type="primary"
        size="large"
        :disabled="!readyForUpload"
        class="flex-1 fs-upload-containers"
        @click="upload"
      >
        Upload
        <template v-if="fileList.length > 0"> file</template>
        <template v-else-if="parsedContainerNumbers.length > 0">
          {{ parsedContainerNumbers.length }} container<template
            v-if="parsedContainerNumbers.length > 1"
            >s</template
          >
        </template>
      </el-button>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
.method-header {
  @apply text-center my-3 text-lg font-semibold;
}
// upload alert style
.el-alert {
  text-align: left;
  margin-top: 5px;
}

.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}

.custom-rotate-180 {
  transform: rotate(180deg) !important;
}
li {
  @apply break-normal text-xs mt-2 ml-2;
}
</style>
