import { TerminalName } from '~/services/apiClient'

export interface ReturnRuleSource {
  terminal: TerminalName
  source: string
  requiresLogin?: boolean
}

export const EMPTY_RETURN_RULE_SOURCES: ReturnRuleSource[] = [
  {
    terminal: TerminalName.Apm,
    source:
      'https://www.apmterminals.com/en/los-angeles/practical-information/empty-receivables-and-open-areas',
  },
  { terminal: TerminalName.Everport, source: 'https://www.etslink.com/' },
  {
    terminal: TerminalName.Fms,
    source: 'https://fenixmarineservices.com/empty-receiving/',
  },
  {
    terminal: TerminalName.Its,
    source: 'https://www.itslb.com/empty-receiving-updates/',
  },
  {
    terminal: TerminalName.Lbct,
    source: 'https://www.lbct.com/Operations/EmptyRestrictions',
  },
  {
    terminal: TerminalName.Pct,
    source: 'https://pct.tideworks.com/fc-PCT/home/default.do?method=page&id=0',
    requiresLogin: true,
  },
  {
    terminal: TerminalName.PierA,
    source:
      'https://piera.tideworks.com/fc-PA/home/default.do?method=page&id=0',
    requiresLogin: true,
  },
  {
    terminal: TerminalName.Trapac,
    source: 'https://losangeles.trapac.com/empty-returns/',
  },
  {
    terminal: TerminalName.Tti,
    source: 'https://www.ttilgb.com/main/index.do',
  },
  {
    terminal: TerminalName.Wbct,
    source: 'https://voyagertrack.portsamerica.com/logon?siteId=WBCT_LA',
  },
  {
    terminal: TerminalName.Yti,
    source: 'https://yti.com/services/#toggle-id-3',
  },
]
