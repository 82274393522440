<script setup lang="ts">
import { useMakeLineAndTypeDescription } from '~/compositions/useMakeLineAndTypeDescription'
import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'

const props = defineProps<{
  dualOpportunities: AppointmentWithContainerInfo[]
}>()
const emit = defineEmits({
  addEmpty: (opportunity: AppointmentWithContainerInfo) => true,
})
const { makeLineAndTypeDescription } = useMakeLineAndTypeDescription()

function startAddEmpty(opportunity: AppointmentWithContainerInfo) {
  emit('addEmpty', opportunity)
}
const noun = computed(() => {
  return props.dualOpportunities.length === 1 ? 'opportunity' : 'opportunities'
})
</script>

<template>
  <el-popover
    v-if="dualOpportunities.length > 0"
    :width="500"
    :persistent="false"
  >
    <template #reference>
      <el-button type="primary" size="default" plain class="w-full relative">
        {{ props.dualOpportunities.length }} {{ noun }}
        <i-mdi:chevron-down class="absolute" style="right: 5px" />
      </el-button>
    </template>
    <!-- Cancel dropdown -->
    <template #default>
      <table class="w-full">
        <thead>
          <tr>
            <th>Container Number</th>
            <th>Line/Type</th>
            <th>Terminal</th>
            <!-- Set to min width required -->
            <th style="width: 10px"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="appointmentInfo of dualOpportunities"
            :key="appointmentInfo.appointment.id"
          >
            <td>{{ appointmentInfo.container_number }}</td>
            <td>
              {{ makeLineAndTypeDescription(appointmentInfo.watch) }}
            </td>
            <td>{{ appointmentInfo.appointment.terminal_label }}</td>
            <td>
              <el-button
                type="primary"
                plain
                @click="startAddEmpty(appointmentInfo)"
              >
                Add empty
              </el-button>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </el-popover>
</template>
