<script lang="ts" setup>
import { ref, computed } from 'vue'
import TruckSelector from './TruckSelector.vue'
import type { TerminalName } from '~/services/apiClient'
import type { Truck } from '~/models/trucks'

const props = withDefaults(
  defineProps<{
    modelValue: string | undefined
    terminal: TerminalName
    autoSelectValue?: boolean
    autofocus?: boolean
    disabled?: boolean
    showIcon?: boolean
  }>(),
  {
    autoSelectValue: false,
    autofocus: false,
    disabled: false,
    showIcon: true,
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | undefined): void
}>()

const selectedTruck = ref<Truck | null>(null)

const licensePlate = computed({
  get: () => props.modelValue,
  set: (value: string | undefined) => emit('update:modelValue', value),
})

function handleTruckSelection(truck: Truck | null) {
  selectedTruck.value = truck
  licensePlate.value = truck?.licensePlate
}
</script>

<template>
  <TruckSelector
    :terminal="terminal"
    :license-plate="licensePlate ?? ''"
    :auto-select-value="autoSelectValue"
    :autofocus="autofocus"
    :disabled="disabled"
    :show-icon="showIcon"
    @update:model-value="handleTruckSelection"
  />
</template>
