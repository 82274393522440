<script lang="ts" setup>
import type { ReturnRuleSource } from './terminals'
import { getTerminalLabel } from '~/constants'
import { useUserStore } from '~/stores/user'
import { useReturnReadingIsStale } from '~/compositions/returnReadingIsStale'
import { useLuxonTimeAgo } from '~/compositions/timeAgo'
import type { ReturnRulesReading } from '~/models/returnRules'

const props = defineProps<{
  rulesSource: ReturnRuleSource
  reading: ReturnRulesReading
  readingIsHistorical: boolean
}>()

const { reading: readingRef } = toRefs(props)
const userStore = useUserStore()
const readingIsOld = useReturnReadingIsStale(readingRef)
const readingIsStale = computed(() => {
  return readingIsOld.value && !props.readingIsHistorical
})
const readingLastChecked = computed(() => {
  if (readingRef.value) {
    return readingRef.value.last_observed
  }
  return null
})
const readingLastCheckedDesc = useLuxonTimeAgo(readingLastChecked)
</script>

<template>
  <div>
    <!-- Warnings and badges -->
    <div class="mb-2">
      <ColoredBadge
        v-if="
          reading.first_observed.equals(reading.first_observed) &&
          readingIsStale
        "
        type="danger"
      >
        <i-mdi:alert class="align-middle mr-1" />
        {{ getTerminalLabel(reading.terminal) }} Empty return rules last fetched
        {{ readingLastCheckedDesc }} and may not reflect latest rules
      </ColoredBadge>
      <ColoredBadge v-else type="success" effect="dark" class="shadow">
        Last checked {{ readingLastCheckedDesc }}
      </ColoredBadge>
      <ColoredBadge
        v-if="reading.last_parsed_time === null"
        type="warning"
        effect="dark"
        class="shadow ml-2"
      >
        Not Parsed
      </ColoredBadge>
      <ColoredBadge v-else type="success" effect="dark" class="shadow ml-2">
        Parsed
      </ColoredBadge>
      <a :href="rulesSource.source" target="_blank">
        <ColoredBadge type="info" class="ml-2">
          View Source
          <template v-if="props.rulesSource.requiresLogin">
            (requires login)
          </template>
          <i-mdi:open-in-new class="align-text-bottom text-xs" />
        </ColoredBadge>
      </a>
    </div>
    <div class="flex h-full">
      <!-- Left (Screenshot) -->
      <div class="flex-1 overflow-y-scroll">
        <h2 class="section-header">Screenshot</h2>
        <el-image
          :key="reading.screenshot_url"
          :src="reading.screenshot_url"
          class="shadow-xl border p-3 rounded-3px max-w-full min-w-30vw min-h-20vh pr-6 pb-6"
        >
          <template #placeholder>
            <el-skeleton :loading="true" animated>
              <template #template>
                <el-skeleton-item variant="image" class="min-h-20vh" />
              </template>
            </el-skeleton>
          </template>
        </el-image>
      </div>
      <!-- Right (Parsed rules) -->
      <div v-if="userStore.is_internal_user" class="flex-1 overflow-y-scroll">
        <h2 class="section-header">Parsed Rules</h2>
        <TerminalEmptyReturnTable
          :key="reading.terminal"
          :reading-first-observed="reading.first_observed_raw"
          :terminal="reading.terminal"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.section-header {
  @apply text-center text-2xl font-bold mb-2;
}
</style>
