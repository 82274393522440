import { defineStore } from 'pinia'
import type { DateTime } from 'luxon'
import { useUserStore } from './user'
import { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'
import type {
  GETContainer,
  GETWatch,
  TransactionDirection,
} from '~/services/apiClient'
import { AppointmentStatus, AppointmentsApi } from '~/services/apiClient'

interface LoadParams {
  after: DateTime
  before?: DateTime
  direction?: TransactionDirection
  loaded?: boolean
  statuses?: AppointmentStatus[]
  limitToRelevantAppointments: boolean
}

export const useAppointmentWithContainerInfoStore = defineStore(
  'appointments-with-container-info',
  () => {
    const userStore = useUserStore()
    const loading = ref(false)
    const appointmentsByID = ref<Map<number, AppointmentWithContainerInfo>>(
      new Map()
    )
    const mostRecentLoadParams = ref<LoadParams | null>(null)

    const appointments = computed(() => {
      const appointments = Array.from(appointmentsByID.value.values())
      appointments.sort((a, b) => {
        return a.appointment.window_start
          .diff(b.appointment.window_start)
          .as('seconds')
      })
      return appointments
    })

    function load(params: LoadParams): Promise<void> {
      loading.value = true
      // mostRecentLoadParams.value = null
      const api = new AppointmentsApi()
      const includeGateTransactions = false
      const includeImportStatuses = false
      // https://api.draydog.com/redoc#tag/Appointments/operation/list_appointments_with_container_info_appointments_with_container_info__get
      return api
        .listAppointmentsWithContainerInfoAppointmentsWithContainerInfoGet(
          params.after.toISO(),
          params.before ? params.before.toISO() : undefined,
          params.direction,
          params.loaded,
          params.statuses,
          includeGateTransactions,
          includeImportStatuses,
          params.limitToRelevantAppointments ?? false
        )
        .then((resp) => {
          mostRecentLoadParams.value = params
          resp.data.forEach((rawApptInfo) => {
            const appointment = new AppointmentWithContainerInfo(
              rawApptInfo,
              userStore.demo_mode
            )
            appointmentsByID.value.set(appointment.appointment.id, appointment)
          })
        })
        .finally(() => {
          loading.value = false
        })
    }
    function clearAllData() {
      appointmentsByID.value = new Map()
    }
    /**
     * For live-updating this store based on container updates that are sent to us over
     * websockets. Piggybacking on this existing feed so we don't have to setup a
     * secondary feed and potentially miss something.
     * NOTE: This will technically miss some updates for containers that have multiple,
     * active appointments.
     * @param container - The container that was updated
     */
    function updateFromContainer(container: GETContainer) {
      container.other_appointment_counts.forEach((apptCount) => {
        if (apptCount.status === AppointmentStatus.Scheduled) {
          // eslint-disable-next-line no-console
          console.log(
            `Received update for appointment with other ` +
              `scheduled appointments: ${container.container_number}`
          )
          // TODO: Load this appointment specifically?
        }
      })
      const appointmentInfo = containerToAppointmentWithContainerInfo(container)
      if (
        appointmentInfo &&
        (appointmentsByID.value.has(appointmentInfo.appointment.id) ||
          appointmentInfoMatchesSearchParams(appointmentInfo))
      ) {
        // eslint-disable-next-line no-console
        console.log(
          `Received update for appointment with container info ${appointmentInfo.container_number}/${appointmentInfo.watch.shipping_line}/${appointmentInfo.watch.container_type}`
        )
        appointmentsByID.value.set(
          appointmentInfo.appointment.id,
          appointmentInfo
        )
      }
    }
    function appointmentInfoMatchesSearchParams(
      apptInfo: AppointmentWithContainerInfo
    ): boolean {
      if (!mostRecentLoadParams.value) {
        // If we haven't loaded anything, don't accept live updates
        return false
      }
      if (
        mostRecentLoadParams.value.after &&
        apptInfo.appointment.window_start < mostRecentLoadParams.value.after
      ) {
        return false
      }
      if (
        mostRecentLoadParams.value.before &&
        apptInfo.appointment.window_start >= mostRecentLoadParams.value.before
      ) {
        return false
      }
      if (
        mostRecentLoadParams.value.direction &&
        apptInfo.appointment.direction !== mostRecentLoadParams.value.direction
      ) {
        return false
      }
      if (
        mostRecentLoadParams.value.loaded !== undefined &&
        apptInfo.appointment.loaded !== mostRecentLoadParams.value.loaded
      ) {
        return false
      }
      if (
        mostRecentLoadParams.value.statuses &&
        !mostRecentLoadParams.value.statuses.includes(
          apptInfo.appointment.status
        )
      ) {
        return false
      }
      // TODO: limitToRelevantAppointments? Not sure how to do this yet.
      return true
    }

    function containerToAppointmentWithContainerInfo(
      container: GETContainer
    ): AppointmentWithContainerInfo | null {
      if (container.latest_appointment) {
        const watch: GETWatch = {
          container_number: container.container_number,
          customer: container.customer,
          state: container.cycle_state,
          master_bill_of_lading: container.master_bl,
          last_related_terminal: container.last_related_terminal,
          shipping_line: container.shipping_line,
          container_type: container.container_type,
          import_out_gated_time: container.import_out_gated_time,
          added_by_user: container.added_by_user,
          assigned_users: container.assigned_users,
          discharged_time: container.discharged_time,
          import_appointment_time: container.import_appointment_time,
          empty_appointment_time: container.empty_appointment_time,
          empty_in_gated_time: container.empty_in_gated_time,
          import_last_free_date: container.last_free_date,
          tags: container.tags,
          vessel: container.vessel,
        }
        return new AppointmentWithContainerInfo(
          {
            appointment: container.latest_appointment,
            watch,
          },
          userStore.demo_mode
        )
      } else {
        return null
      }
    }

    return {
      loading,
      appointments,
      load,
      clearAllData,
      updateFromContainer,
    }
  }
)
