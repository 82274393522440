import { useRouteQuery } from '@vueuse/router'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { useUserStore } from './user'

export const useCurrentOrgStore = defineStore('current-org', () => {
  const userStore = useUserStore()
  const router = useRouter()
  const urlScac = useRouteQuery<string | undefined>('scac', undefined, {
    mode: 'push',
  })
  const urlOrgId = useRouteQuery<string | undefined>('org_id', undefined, {
    mode: 'push',
  })
  const currentOrg = computed(() => {
    return userStore.currentOrg
  })
  async function selectOrgFromURL() {
    await nextTick()

    if (urlOrgId.value !== undefined) {
      const parsedInput = parseInt(urlOrgId.value, 10)

      if (!isNaN(parsedInput)) {
        const org = userStore.userInfo?.organizations.find(
          (org) => org.id === parsedInput
        )
        if (org) {
          // Replace the current route with the updated query parameters
          urlScac.value = org.scac
          const currentQuery = { ...router.currentRoute.value.query }
          delete currentQuery.org_id
          currentQuery.scac = urlScac.value
          router.replace({ query: currentQuery })
        }
      }
    }

    if (urlScac.value && !(currentOrg.value?.scac === urlScac.value)) {
      await switchToOrgByScac(urlScac.value)
    }
  }
  // When we navigate, often that would clear the SCAC, so let's just set it again if
  // that is the case
  router.beforeEach((to, from, next) => {
    if (currentOrg.value && !to.query.scac) {
      to.query = { ...to.query, scac: currentOrg.value.scac }
      next(to)
      return
    }
    next()
  })

  async function switchToOrgByScac(scac: string) {
    const org = userStore.userInfo?.organizations.find(
      (org) => org.scac === scac
    )
    if (org) {
      urlScac.value = org.scac
      await userStore.switchToOrg(org.id)
      return true
    }
    urlScac.value = currentOrg.value?.scac
    return false
  }
  return { switchToOrgByScac, selectOrgFromURL }
})
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCurrentOrgStore, import.meta.hot))
}
