import type { ShiftGroup } from './types'
import { AppointmentTransactionGroup, TransactionGroup } from './types'
import type { Container } from '~/models/containers'
import type { Appointment } from '~/models/appointments'
import { MoveType } from '~/models/appointments'
import type { LinkedAppointment } from '~/services/apiClient'

function makeAppointmentKey(
  appointment: Appointment | LinkedAppointment
): string {
  return `${appointment.container_number}!!${appointment.terminal_reference}`
}

export function groupContainersIntoTransactions(
  containers: Container[]
): TransactionGroup[] {
  // Create lookups
  const containersByAppointmentKey: Map<string, Container> = new Map()
  containers.forEach((container) => {
    if (!container.booked_appointment) {
      return
    }
    const appointmentKey = makeAppointmentKey(container.booked_appointment!)
    containersByAppointmentKey.set(appointmentKey, container)
  })

  const containersByLinkedAppointmentKey: Map<string, Container[]> = new Map()
  function addLink(linkedAppointmentKey: string, container: Container) {
    if (!containersByLinkedAppointmentKey.has(linkedAppointmentKey)) {
      containersByLinkedAppointmentKey.set(linkedAppointmentKey, [])
    }
    containersByLinkedAppointmentKey.get(linkedAppointmentKey)!.push(container)
  }

  containers.forEach((container) => {
    const appointment = container.booked_appointment
    if (!appointment) {
      // eslint-disable-next-line no-console
      console.log(`Container without appointment: ${container.key}`)
      return
    }
    const appointmentKey = makeAppointmentKey(appointment)
    appointment.linked_appointments.forEach((linkedAppointment) => {
      const linkedKey = makeAppointmentKey(linkedAppointment)
      addLink(linkedKey, container)
      const linkedContainer = containersByAppointmentKey.get(linkedKey)
      if (linkedContainer) {
        addLink(appointmentKey, linkedContainer)
      }
    })
  })
  // Create transactions
  const transactions: TransactionGroup[] = []
  const keysProcessed: Set<string> = new Set()
  containers.forEach((container) => {
    if (!container.booked_appointment) return
    const appointmentKey = makeAppointmentKey(container.booked_appointment!)
    if (keysProcessed.has(appointmentKey)) {
      return
    }
    const linkedContainers =
      containersByLinkedAppointmentKey.get(appointmentKey) || []
    // Can create duplicates if things are "doubly" linked, but that's fine as we
    // dedupe by move type
    const containersInTransaction = linkedContainers.concat(container)
    const containersByMoveType = new Map<MoveType, Container>()
    containersInTransaction.forEach((container) => {
      const moveType = container.latest_appointment!.moveType
      containersByMoveType.set(moveType, container)
    })
    const emptyInContainer = containersByMoveType.get(MoveType.EmptyIn)
    const loadOutContainer = containersByMoveType.get(MoveType.LoadOut)
    if (emptyInContainer) {
      keysProcessed.add(
        makeAppointmentKey(emptyInContainer.latest_appointment!)
      )
    }
    if (loadOutContainer) {
      keysProcessed.add(
        makeAppointmentKey(loadOutContainer.latest_appointment!)
      )
    }
    if (emptyInContainer || loadOutContainer) {
      transactions.push(
        new TransactionGroup({
          empty_in: emptyInContainer,
          load_out: loadOutContainer,
        })
      )
    }
  })
  // Check for duplicates
  const seenKeys = new Map<string, TransactionGroup>()
  for (const transaction of transactions) {
    let source
    let key
    if (transaction.load_out) {
      source = 'load_out'
      key = transaction.load_out.key
    } else if (transaction.empty_in) {
      source = 'empty_in'
      key = transaction.empty_in.key
    } else {
      throw new Error('Transaction without key')
    }
    if (seenKeys.has(key)) {
      // console.log(transaction)
      // console.log(seenKeys.get(key))
      throw new Error(`Duplicate transaction key ${key} ${source}`)
    }
    seenKeys.set(key, transaction)
  }
  return transactions
}

export function shiftGroupsToAppointedContainers(
  shiftGroups: ShiftGroup[]
): AppointmentTransactionGroup[] {
  const rows: AppointmentTransactionGroup[] = []
  let index = 0
  shiftGroups.sort((a, b) => {
    return a.shiftStart.toMillis() - b.shiftStart.toMillis()
  })
  shiftGroups.forEach((shiftGroup) => {
    let isFirstRowInShift = true
    const hourGroups = Array.from(shiftGroup.containersByHour.values())
    hourGroups.sort((a, b) => a.startTime.toMillis() - b.startTime.toMillis())
    hourGroups.forEach((hourGroup) => {
      hourGroup.transactions.forEach((transaction, shiftIndex) => {
        rows.push(
          new AppointmentTransactionGroup({
            transaction,
            hourGroup,
            shiftGroup,
            isFirstRowInHour: shiftIndex === 0,
            isFirstRowInShift,
            index,
          })
        )
        index += 1
        isFirstRowInShift = false
      })
    })
  })
  return rows
}
