<script lang="ts" setup>
import { ElMessage } from 'element-plus'
import { DateTime } from 'luxon'
import { useContainerFilters } from '~/compositions/containerFilters'
import {
  ContainerWatchState,
  ContainersApi,
  GETContainerFileExportFileTypeEnum,
} from '~/services/apiClient'
import { useContainerStore } from '~/stores/containers'

const props = defineProps<{
  containers: string[]
}>()
const emit = defineEmits<{ (e: 'close'): void }>()
const containerStore = useContainerStore()
const containerFilters = useContainerFilters()
const dialogOpen = ref(true)
const loading = ref(false)
function closeDialog() {
  dialogOpen.value = false
  emit('close')
}
const downloadOptions = [
  {
    description: 'On Vessel',
    states: [ContainerWatchState.ImportTrackingOnVessel],
  },
  {
    description: 'Unavailable',
    states: [ContainerWatchState.ImportTracking],
  },
  {
    description: 'Available',
    states: [ContainerWatchState.ImportApptBooking],
  },
  {
    description: 'Appointed',
    states: [ContainerWatchState.ImportApptBooked],
  },
  {
    description: 'Out Gated',
    states: [
      ContainerWatchState.ImportPickedUp,
      ContainerWatchState.ImportEmptyApptBooked,
      ContainerWatchState.ExportApptBooked,
    ],
  },
  {
    description: 'Terminated',
    states: [ContainerWatchState.ImportEmptyReturned],
  },
]

const selectOptions = computed(() =>
  downloadOptions.map((option) => {
    let numContainers = 0
    for (const state of option.states) {
      numContainers += containerStore.statusCounts.get(state) ?? 0
    }
    return {
      description: option.description,
      numContainers,
    }
  })
)
const isContainersSelected = ref(props.containers.length > 0)
const stateDescriptions = ref([] as string[])
const numContainersThatWouldBeDownloadedDesc = computed(() => {
  let numContainers = 0
  if (isContainersSelected.value) {
    numContainers = props.containers.length
  } else {
    for (const description of stateDescriptions.value) {
      const option = selectOptions.value.find(
        (o) => o.description === description
      )
      if (!option) {
        throw new Error(`Could not find option for ${description}`)
      }
      numContainers += option.numContainers
    }
  }
  let desc = `${numContainers} container`
  if (numContainers !== 1) {
    desc += 's'
  }
  return desc
})
watch(
  stateDescriptions,
  () => {
    if (stateDescriptions.value.length > 0) {
      isContainersSelected.value = false
    } else if (
      stateDescriptions.value.length === 0 &&
      props.containers.length > 0
    ) {
      isContainersSelected.value = true
    }
  },
  { deep: true }
)
watch(
  isContainersSelected,
  () => {
    if (isContainersSelected.value) {
      stateDescriptions.value = []
    }
  },
  { deep: true }
)
function downloadContainers(file_type: GETContainerFileExportFileTypeEnum) {
  loading.value = true
  let states: ContainerWatchState[] | undefined = []
  let containerNumbers: string[] | undefined = []
  if (
    isContainersSelected.value === false &&
    stateDescriptions.value.length === 0
  ) {
    loading.value = false
    ElMessage.warning('Select a status to export or your selected containers')
    return
  }
  if (isContainersSelected.value === true) {
    if (props.containers.length === 0) {
      loading.value = false
      ElMessage.warning('No containers selected')
      return
    }
    containerNumbers = props.containers
  } else {
    for (const description of stateDescriptions.value) {
      const option = downloadOptions.find((o) => o.description === description)
      if (!option) {
        throw new Error(`Could not find option for ${description}`)
      }
      states = states.concat(option.states)
    }
  }
  const api = new ContainersApi()
  const now = DateTime.now()
  const fileName = `Containers Download ${now.toFormat(
    'yyyy-MM-dd HH_mm_ss'
  )}.${file_type}`
  if (states.length === 0) {
    states = undefined
  }
  containerNumbers = props.containers
  if (containerNumbers.length === 0) {
    containerNumbers = undefined
  }
  api
    .fileExportContainersFileExportPost(
      {
        container_cycle_states: states,
        file_type,
        ...containerFilters.allFilters.value,
        container_numbers: containerNumbers,
      },
      { responseType: 'blob' }
    )
    .then((resp) => {
      if (resp.status === 200) {
        const blob = new Blob([resp.data], {
          type: resp.headers['content-type'],
        })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      }
    })
    .finally(() => {
      loading.value = false
      closeDialog()
    })
}
</script>

<template>
  <el-dialog
    :model-value="dialogOpen"
    title="Download Container Data"
    width="30%"
    center
    :append-to-body="true"
    class="px-5"
    @close="emit('close')"
  >
    <el-form label-position="top">
      <div>
        <el-form-item class="mt-5" label="Download selected containers:">
          <el-checkbox
            v-model="isContainersSelected"
            :label="true"
            :disabled="props.containers.length === 0"
            border
            class="option-checkbox"
          >
            <span>{{ props.containers.length }} Selected</span>
          </el-checkbox>
        </el-form-item>
      </div>
      <div>
        <el-form-item class="mt-7" label="Download by container status:">
          <el-checkbox-group v-model="stateDescriptions">
            <el-checkbox
              v-for="item in selectOptions"
              :key="item.description"
              :label="item.description"
              border
              class="option-checkbox"
            >
              <span class="w-24 text-left inline-block">
                {{ item.description }}
              </span>
              <span>({{ item.numContainers }} containers)</span>
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>
    </el-form>
    <template #footer>
      <div class="button-holder">
        <el-button
          :loading="loading"
          size="large"
          type="primary"
          :disabled="!isContainersSelected && stateDescriptions.length === 0"
          @click="downloadContainers(GETContainerFileExportFileTypeEnum.Csv)"
        >
          Download {{ numContainersThatWouldBeDownloadedDesc }} as CSV
        </el-button>
      </div>
      <div class="button-holder">
        <el-button
          :loading="loading"
          size="large"
          type="primary"
          :disabled="!isContainersSelected && stateDescriptions.length === 0"
          @click="downloadContainers(GETContainerFileExportFileTypeEnum.Xlsx)"
        >
          Download {{ numContainersThatWouldBeDownloadedDesc }} as Excel
        </el-button>
      </div>
      <div class="button-holder">
        <el-button size="large" @click="closeDialog"> Cancel </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.button-holder {
  button {
    width: 100%;
    margin-top: 5px;
  }
}
.option-checkbox {
  width: 100%;
  @apply mb-2;
}
</style>
