<script lang="ts" setup>
/* Displays a table of empty return locations, optionally filtering by shipping line,
container type */
import { computed } from 'vue'
import type {
  EmptyAppointmentSlotsLoadedEvent,
  TerminalName,
} from '~/services/apiClient'

import { EventName, Shift } from '~/services/apiClient'
import type {
  CategoryKey,
  CategoryKeyWithTimestamps,
} from '~/stores/returnRulesLatest'
import { useReturnRulesLatest } from '~/stores/returnRulesLatest'
import { useEmptyAppointmentSlotsStore } from '~/stores/emptyAppointmentSlots'
import type { SoketiEvent } from '~/compositions/configureSoketi'
import { useSoketi } from '#compositions/configureSoketi'
import { useAppointmentSlotEvents } from '~/compositions/soketi/useAppointmentSlotEvents'
import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'
import { useReturnRulesAndFilters } from '~/compositions/useReturnRulesAndFilters'
import useStartOfToday from '~/compositions/useStartOfToday'

// Properties
const props = defineProps<{
  searchedContainerNumber: string | null
  existingSingleEmptyAppointments: AppointmentWithContainerInfo[]
}>()
const DEFAULT_DAYS_TO_VIEW = 5
const {
  bind: bindWebsocketEvent,
  unbind: unbindWebsocketEvent,
  isBound: isWebsocketEventBound,
  eventHandlers: websocketEventHandlers,
} = useSoketi()
// Refs
const showAllDays = ref(false)

// Stores + compositions
const apptSlotEventHelpers = useAppointmentSlotEvents()
const latestReturnRulesStore = useReturnRulesLatest()
const emptyAppointmentSlotsStore = useEmptyAppointmentSlotsStore()
const lookupReturnRule = latestReturnRulesStore.lookupRule
const loadingReturnRules = toRef(latestReturnRulesStore, 'loading')
const datesWithRules = toRef(latestReturnRulesStore, 'datesWithRules')
const {
  applicableReturnRuleCategoryKeys,
  applicableNotClosedCategoryKeys,
  shippingLine,
  containerType,
  terminal,
} = useReturnRulesAndFilters()
// Computed properties
const loading = computed(() => {
  const loadingSlotPreRequisites =
    !apptSlotEventHelpers.prerequisitesLoaded.value
  return loadingReturnRules.value || loadingSlotPreRequisites
})
const showShippingLine = computed(() => !shippingLine.value)
const showContainerType = computed(() => !containerType.value)
const showTerminal = computed(() => !terminal.value)
const startOfToday = useStartOfToday()

const dates = computed(() => {
  if (showAllDays.value) {
    return datesWithRules.value
  }
  return datesWithRules.value.slice(0, DEFAULT_DAYS_TO_VIEW)
})
const numAdditionalDaysToView = computed(() => {
  return Math.max(datesWithRules.value.length - DEFAULT_DAYS_TO_VIEW, 0)
})
const existingSingleEmptyAppointmentsByTerminalAndShift = ref(
  new Map<TerminalName, Map<string, AppointmentWithContainerInfo[]>>()
)
const containerCountsByTerminal = ref(new Map<TerminalName, number>())

// Functions
function handleExistingSingleEmptyAppointmentsChange() {
  const lookup = new Map<
    TerminalName,
    Map<string, AppointmentWithContainerInfo[]>
  >()
  const counts = new Map<TerminalName, number>()
  for (const appointmentInfo of props.existingSingleEmptyAppointments) {
    const appointment = appointmentInfo.appointment
    if (appointment.window_start < startOfToday.value) {
      continue
    }
    // Ignore empty appointments that are part of a dual transaction
    if (appointment.linked_appointments?.length) {
      continue
    }
    // Add to lookups
    const terminalLookup =
      lookup.get(appointment.terminal) ??
      new Map<string, AppointmentWithContainerInfo[]>()
    lookup.set(appointment.terminal, terminalLookup)
    const appointmentInfos =
      terminalLookup.get(appointment.scheduledShift) ?? []
    appointmentInfos.push(appointmentInfo)
    terminalLookup.set(appointment.scheduledShift, appointmentInfos)
    counts.set(
      appointment.terminal,
      (counts.get(appointment.terminal) ?? 0) + 1
    )
  }
  existingSingleEmptyAppointmentsByTerminalAndShift.value = lookup
  containerCountsByTerminal.value = counts
}

function handleEmptyAppointmentSlotEvent(event: SoketiEvent) {
  emptyAppointmentSlotsStore.handleEvent(
    event as EmptyAppointmentSlotsLoadedEvent
  )
}
async function updateEmptyAppointmentSlotEventHandlers(
  categories: CategoryKeyWithTimestamps[]
) {
  await apptSlotEventHelpers.waitForPreRequisitesToLoadIfNeeded()
  // Calculate all channel names
  const channelNames = new Set<string>()
  for (const key of categories) {
    const channelName = apptSlotEventHelpers.getAppointmentSlotChannelName(
      key.terminal,
      key.shippingLine,
      key.containerType
    )
    channelNames.add(channelName)
  }
  // Add missing categories
  for (const channelName of channelNames) {
    if (
      !isWebsocketEventBound(channelName, EventName.EmptyAppointmentSlotsLoaded)
    ) {
      // console.log(`binding ${channelName}`)
      bindWebsocketEvent(
        channelName,
        EventName.EmptyAppointmentSlotsLoaded,
        handleEmptyAppointmentSlotEvent
      )
    }
  }
  // Remove categories that are no longer needed
  for (const channelName of websocketEventHandlers.value.keys()) {
    if (
      channelName.startsWith('private-empty-appointment-slots-') &&
      !channelNames.has(channelName)
    ) {
      // console.log(`unbinding ${channelName}`)
      unbindWebsocketEvent(
        channelName,
        EventName.EmptyAppointmentSlotsLoaded,
        handleEmptyAppointmentSlotEvent
      )
    }
  }
}
// Watchers
watch(
  () => props.existingSingleEmptyAppointments,
  handleExistingSingleEmptyAppointmentsChange,
  {
    immediate: true,
  }
)

function makeCategoryTypeKey(category: CategoryKey): string {
  return `${category.terminal}-${category.shippingLine}-${category.containerType}`
}

watch(
  applicableNotClosedCategoryKeys,
  async (newValues, oldValues) => {
    // Load slots for any categories that were not previously loaded
    const oldValueKeys = new Set<string>()
    if (oldValues) {
      oldValues.forEach((x) => {
        oldValueKeys.add(makeCategoryTypeKey(x))
      })
    }
    const categoriesToLoad: CategoryKey[] = []
    newValues.forEach((x) => {
      const key = makeCategoryTypeKey(x)
      if (!oldValueKeys.has(key)) {
        categoriesToLoad.push(x)
      }
    })
    if (categoriesToLoad.length > 0) {
      await apptSlotEventHelpers.waitForPreRequisitesToLoadIfNeeded()
    }
    for (const category of categoriesToLoad) {
      emptyAppointmentSlotsStore.load(category)
    }
  },
  {
    immediate: true,
  }
)
onMounted(() => {
  apptSlotEventHelpers.loadPreRequisites()
  updateEmptyAppointmentSlotEventHandlers([])
})
// Will be regenerated many times even if not changed
watch(applicableReturnRuleCategoryKeys, updateEmptyAppointmentSlotEventHandlers)

const hasZeroRules = computed(
  () => applicableReturnRuleCategoryKeys.value.length === 0
)
</script>

<template>
  <div
    v-if="loading"
    class="flex justify-center items-center h-30vh text-xl text-gray-600"
  >
    Loading...
  </div>

  <div
    v-else-if="hasZeroRules"
    class="bg-gray-100 border border-gray-300 p-4 rounded-md text-gray-600 text-xl"
  >
    <p class="font-medium">
      <i-mdi:alert-circle class="text-yellow-500 align-middle mr-2" />
      No rules found.
    </p>
    <p>Please try selecting another shipping line or container type.</p>
    <!-- Add a message and button -->
    <p class="mt-2">
      If you think this is wrong, please send us feedback
      <span class="emoji" style="font-size: 40px">&#x1F449;</span>
    </p>
  </div>

  <table v-else class="err-table">
    <!-- Header -->
    <thead>
      <tr>
        <!-- Column headers -->
        <th v-if="showShippingLine" class="label" rowspan="2">
          <VesselIcon class="align-middle" />
          Line
        </th>
        <th v-if="showTerminal" class="label" rowspan="2">
          <i-mdi:pier-crane class="align-text-bottom" />
          Terminal
        </th>
        <th v-if="showContainerType" class="label" rowspan="2">
          <EmptyContainerIcon class="align-middle" />
          Type
        </th>
        <!-- Date headers -->
        <th
          v-for="(date, index) of dates"
          :key="date.toISODate()"
          colspan="2"
          class="relative"
        >
          <div v-if="index === 0" class="header-label text-sm">
            <i-mdi:calendar class="align-middle" />
            Date:
          </div>
          <span> {{ date.toFormat('cccc M/d') }}</span>
          <el-button
            v-if="numAdditionalDaysToView > 0 && index === dates.length - 1"
            size="small"
            type="primary"
            class="absolute right-2"
            @click="showAllDays = !showAllDays"
          >
            View <template v-if="showAllDays">Less</template>
            <template v-else>More</template>
          </el-button>
        </th>
      </tr>
      <!-- Shifts -->
      <tr class="text-sm">
        <template v-for="(_, index) of dates" :key="_.toISODate()">
          <th>
            <div class="relative">
              <div v-if="index === 0" class="header-label text-xs pr-1">
                <i-mdi:clock class="align-middle mb-1px" />
                Shift:
              </div>
              <span> 1st</span>
            </div>
          </th>
          <th>2nd</th>
        </template>
      </tr>
    </thead>
    <tbody>
      <!-- Terminals/rules rows -->
      <tr
        v-for="(row, index) in applicableReturnRuleCategoryKeys"
        :key="`row ${row.shippingLine}-${row.containerType}-${row.terminal}`"
        :aria-label="`${row.shippingLine} ${row.containerType} ${row.terminal}`"
      >
        <!-- Labels -->
        <ReturnRulesRowHeaders
          :key="`${row.shippingLine}-${row.containerType}-${
            row.terminal
          }-${row.lastChecked.toISO()}`"
          :row="row"
          :previous-row="
            index > 0 ? applicableReturnRuleCategoryKeys[index - 1] : null
          "
          :container-count="containerCountsByTerminal.get(row.terminal) ?? 0"
          :show-shipping-line="showShippingLine"
          :show-terminal="showTerminal"
          :show-container-type="showContainerType"
        />
        <!-- Results/body -->
        <template v-for="date of dates" :key="date.toISODate()">
          <template
            v-for="shift of [Shift.First, Shift.Second]"
            :key="`${date.toISODate()}_${shift}`"
          >
            <ReturnRulesTableTdWithPopover
              :return-rule="
                lookupReturnRule(
                  row.shippingLine,
                  row.containerType,
                  row.terminal,
                  date,
                  shift
                )
              "
              :shipping-line="row.shippingLine"
              :container-type="row.containerType"
              :single-empty-appointments-lookup="
                existingSingleEmptyAppointmentsByTerminalAndShift
              "
              :terminal="row.terminal"
              :date="date"
              :shift="shift"
              :searched-container-number="props.searchedContainerNumber"
            />
          </template>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<style lang="scss">
$label-width: 60px;

table.err-table {
  table-layout: fixed;
  $border-radius: 3px;
  $interior-border: 1px solid theme('colors.gray.400');
  $strong-header-color: theme('colors.gray.600');
  border-collapse: collapse;
  border-radius: $border-radius;
  border-style: hidden; /* hide standard table (collapsed) border */
  > thead,
  > tbody > {
    tr:first-child {
      th:first-child {
        border-top-left-radius: $border-radius;
      }
      th:last-child {
        border-top-right-radius: $border-radius;
      }
    }
    tr:last-child {
      th:first-child {
        border-bottom-left-radius: $border-radius;
      }
      td:last-child {
        border-bottom-right-radius: $border-radius;
      }
    }
    tr > th.label {
      border-right: $interior-border;
    }
  }
  > thead {
    @apply text-base;
    color: $strong-header-color;
    tr th {
      @apply py-2;
    }
    tr:first-child {
      th:nth-child(even) {
        @apply bg-gray-100;
      }
      th:nth-child(odd) {
        @apply bg-gray-50;
      }
    }
    tr:last-child {
      border-top: $interior-border;
      th {
        // Striping affect
        &:nth-child(odd) {
          @apply bg-gray-100;
        }
      }
    }
    th.label {
      @apply text-base;
    }
    .header-label {
      @apply absolute left-0 top-0 h-full flex items-center justify-center pl-2;
      width: $label-width;
    }
    .label,
    .header-label {
      @apply text-gray-500;
    }
  }
  > tbody > tr > {
    th {
      @apply text-gray-500;
      width: 60px;
      .terminal-name-label {
        color: $strong-header-color;
      }
    }
    td,
    th {
      border-top: $interior-border;
    }
  }
}
</style>
