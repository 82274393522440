import { DateTime } from 'luxon'
import startCase from 'lodash-es/startCase'
import { faker } from '@faker-js/faker/locale/en'
import {
  TERMINALS_REQUIRING_APPOINTMENT_CHECK_IN,
  getPortalLabel,
  getTerminalLabel,
} from '~/constants'
import type {
  GETAppointment,
  LinkedAppointment,
  PortalName,
  Shift,
  SimpleUser,
} from '~/services/apiClient'
import {
  AppointmentStatus,
  TerminalName,
  TransactionDirection,
} from '~/services/apiClient'
import { moveDescription } from '~/utils'
import { shiftFromTime, shiftNameFromTime } from '~/logic/shifts'

// Move Type enum
export enum MoveType {
  LoadOut = 'Load Out',
  LoadIn = 'Load In',
  EmptyOut = 'Empty Out',
  EmptyIn = 'Empty In',
}

export class Appointment {
  id: number
  container_number: string
  terminal: TerminalName
  status: AppointmentStatus
  truck_license_plate_number?: string
  truck_id: number | null
  terminal_block?: string
  window_start: DateTime
  window_end?: DateTime
  created_at_terminal?: DateTime
  last_observed_changed: DateTime
  last_observed: DateTime | null = null
  terminal_reference: string
  raw_display_terminal_reference: string | null
  linked_appointments: LinkedAppointment[]
  extra_data: Object | undefined
  direction: TransactionDirection
  loaded: boolean
  requested_by: SimpleUser | null
  rescheduled_by: SimpleUser | null
  source: PortalName
  moveType: MoveType
  scheduledShift: string
  shift: Shift
  shiftDate: DateTime
  isScheduled: boolean

  constructor(appt: GETAppointment, demoMode: boolean) {
    this.id = appt.id
    this.container_number = appt.container_number
    this.terminal = appt.terminal
    this.status = appt.status
    this.truck_license_plate_number = appt.truck_license_plate_number
    this.truck_id = appt.truck_id ?? null
    if (this.truck_license_plate_number && demoMode) {
      this.truck_license_plate_number = faker.vehicle.vin().slice(0, 7)
    }
    this.terminal_block = appt.terminal_block
    this.window_start = DateTime.fromISO(appt.window_start)
    this.window_end = appt.window_end
      ? DateTime.fromISO(appt.window_end)
      : undefined
    this.linked_appointments = appt.linked_appointments || []
    this.created_at_terminal = appt.created_at_terminal
      ? DateTime.fromISO(appt.created_at_terminal)
      : undefined
    this.last_observed_changed = DateTime.fromISO(appt.observed)
    if (appt.last_observed) {
      this.last_observed = DateTime.fromISO(appt.last_observed)
    }
    this.terminal_reference = appt.terminal_reference
    this.raw_display_terminal_reference =
      appt.display_terminal_reference || null
    this.direction = appt.direction
    this.loaded = appt.loaded
    this.extra_data = appt.extra_data
    this.requested_by = appt.requested_by || null
    this.rescheduled_by = appt.rescheduled_by || null
    this.source = appt.source
    this.moveType = calcMoveType(this.direction, this.loaded)
    this.scheduledShift = shiftNameFromTime(this.window_start)
    const [shiftDate, shift] = shiftFromTime(this.window_start)
    this.shift = shift
    this.shiftDate = shiftDate
    this.isScheduled = appt.status === AppointmentStatus.Scheduled
  }

  get isStale(): boolean {
    if (this.last_observed) {
      return this.last_observed < DateTime.now().minus({ hours: 2 })
    } else {
      return false
    }
  }

  get display_terminal_reference(): string {
    if (this.raw_display_terminal_reference) {
      return this.raw_display_terminal_reference
    }
    return this.terminal_reference.replace(/_/, ' / ')
  }

  get booked_on_dray_dog(): boolean {
    return Boolean(this.requested_by || this.rescheduled_by)
  }

  get booked_by(): string | null {
    const bookedBy = this.requested_by || this.rescheduled_by
    if (bookedBy !== null) {
      if (bookedBy.first_name === 'Dray Dog') {
        return null
      }
      const lastName = bookedBy.last_name
      if (lastName) {
        const lastInitial = lastName.charAt(0) + '.'
        return `${bookedBy.first_name} ${lastInitial}`
      } else {
        return `${bookedBy.first_name}`
      }
    } else {
      return null
    }
  }

  get terminal_name() {
    return getTerminalLabel(this.terminal)
  }

  get portal_name() {
    return getPortalLabel(this.source)
  }

  get window_date() {
    return this.window_start.toFormat('ccc, LLL d')
  }

  get window_description(): string {
    let s = windowTimeDesc(this.window_start)
    if (this.window_end) {
      const endTime = windowTimeDesc(this.window_end)
      s += ` - ${endTime}`
    }
    return s
  }

  get created_at_terminal_fmtd(): string {
    if (this.created_at_terminal)
      return this.created_at_terminal.toFormat('M/d H:mm')
    else return ''
  }

  get last_observed_changed_fmtd(): string {
    return this.last_observed_changed.toLocaleString(DateTime.DATETIME_SHORT)
  }

  get type(): string {
    return moveDescription(this.direction, this.loaded)
  }

  get terminal_label(): string {
    if (this.terminal) {
      return getTerminalLabel(this.terminal)
    } else {
      return '-'
    }
  }

  get own_chassis_description(): string | null {
    if (this.terminal === TerminalName.Apm && this.extra_data) {
      if ('own_chassis' in this.extra_data) {
        if (this.extra_data.own_chassis) {
          return 'Own Chassis'
        } else {
          return 'Pool Chassis'
        }
      } else {
        return null
      }
    } else {
      return null
    }
  }

  get check_in_status(): boolean | null {
    if (
      TERMINALS_REQUIRING_APPOINTMENT_CHECK_IN.has(this.terminal) &&
      this.extra_data
    ) {
      if ('checked_in' in this.extra_data) {
        return this.extra_data.checked_in as boolean | null
      } else {
        return null
      }
    } else {
      return null
    }
  }

  get status_label(): string {
    return startCase(this.status)
  }
}

function windowTimeDesc(dt: DateTime): string {
  return dt.toLocaleString({
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  })
}

function calcMoveType(
  direction: TransactionDirection,
  loaded: boolean
): MoveType {
  if (direction === TransactionDirection.Inbound) {
    return loaded ? MoveType.LoadIn : MoveType.EmptyIn
  } else {
    return loaded ? MoveType.LoadOut : MoveType.EmptyOut
  }
}
