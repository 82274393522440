<script lang="ts" setup>
import { DateTime } from 'luxon'
import type { BookEvent } from './events'
import { BUCKET_INFOS } from '~/logic/appointmentBucketing'
import type { BaseAppointmentSlot } from '~/models/appointmentSlot'
import { AppointmentSlot } from '~/models/appointmentSlot'
import type { Container } from '~/models/containers'

const props = defineProps<{ loading: boolean; slots: BaseAppointmentSlot[] }>()
const emit = defineEmits({
  book: (event: BookEvent) => true,
})

function parseDate(dateStr: string) {
  return DateTime.fromFormat(dateStr, 'yyyy-MM-dd')
}
function formatDay(date: DateTime) {
  return date.toFormat('yyyy-MM-dd')
}
const container = inject<Container | null>('container', null)
const slotsByDay = computed(() => {
  const slotsByDay = new Map<string, BaseAppointmentSlot[]>()
  const today = DateTime.now().endOf('day')
  let latestAvailability = today
  const slots = [...props.slots]
  // Set existing appointment on appropriate slot
  if (container && container.booked_appointment) {
    const appointment = container.booked_appointment
    let slotFound = false
    for (const slot of slots) {
      if (slot.window_start.equals(appointment.window_start)) {
        slot.booked_appointment = appointment
        slotFound = true
        break
      }
    }
    if (!slotFound) {
      // Add dummy slot for existing appointment if there's no matching slot
      const dummyBookedAppointmentSlot = new AppointmentSlot(
        {
          terminal: appointment.terminal,
          container_number: appointment.container_number,
          has_availability: false,
          slots: [],
          observed: DateTime.now().toISO(),
        },
        {
          window_start: appointment.window_start.toISO(),
          window_end: appointment.window_end?.toISO(),
          num_appointments_available: 0,
        }
      )
      dummyBookedAppointmentSlot.booked_appointment = appointment
      slots.push(dummyBookedAppointmentSlot)
    }
  }
  for (const slot of slots) {
    if (slot.window_start > latestAvailability)
      latestAvailability = slot.window_start.endOf('day')

    const day = formatDay(slot.window_start)
    if (!slotsByDay.has(day)) slotsByDay.set(day, [])

    slotsByDay.get(day)!.push(slot)
  }
  // Add days with no slots
  let day = today
  while (day < latestAvailability) {
    const dayStr = formatDay(day)
    if (!slotsByDay.has(dayStr)) slotsByDay.set(dayStr, [])

    day = day.plus({ days: 1 })
  }
  for (const slots of slotsByDay.values())
    slots.sort((a, b) => a.window_start.diff(b.window_start).seconds)

  const days = Array.from(slotsByDay.entries()).sort()
  return days
})
</script>

<template>
  <table v-loading="loading && slotsByDay.length === 0" class="w-full">
    <thead>
      <th />
      <th
        v-for="bucketInfo in BUCKET_INFOS"
        :key="bucketInfo.description"
        class="text-xs font-semibold"
      >
        {{ bucketInfo.description }}
      </th>
    </thead>
    <tbody>
      <AppointmentSlotsDayRow
        v-for="[dayStr, daySlots] in slotsByDay"
        :key="dayStr"
        :slots="daySlots"
        :date="parseDate(dayStr)"
        @book="emit('book', $event)"
      />
    </tbody>
  </table>
</template>
