<script lang="ts" setup>
import { EDITABLE_CONNECTION_TYPES } from '~/constants'
import { useConnectionsStore } from '~/stores/connections'

const connectionsStore = useConnectionsStore()
onMounted(() => {
  connectionsStore.load()
})
</script>

<template>
  <div class="mb-4 max-w-800px">
    <el-alert title="Directions" type="info" show-icon :closable="false">
      To use Dray Dog, you must have valid login credentials for the following
      accounts.
    </el-alert>
  </div>
  <div v-loading="connectionsStore.loading">
    <div
      v-for="connectionMeta in EDITABLE_CONNECTION_TYPES"
      :key="connectionMeta.type"
      class="flex"
    >
      <a
        :href="connectionMeta.url"
        target="blank"
        rel="noreferrer"
        class="portal-link"
      >
        <span class="underline">{{ connectionMeta.label }}</span>
        <i-mdi:open-in-new class="align-text-bottom ml-1" />
      </a>
      <CredentialsInput :connection-meta="connectionMeta" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.portal-link {
  @apply inline-block w-210px text-right pr-5px mt-3 mr-2;
}
</style>
