<script lang="ts" setup>
import { ElMessageBox } from 'element-plus'
import { Plus } from '@element-plus/icons-vue'
import { useRouteQuery } from '@vueuse/router'
import { storeToRefs } from 'pinia'
import { useGetCapacitySetLabel } from './helpers'
import { useCapacitiesStore } from '~/stores/capacities'
import { confirmBox } from '~/utils'
import type { CapacitySet } from '~/models/capacitySets'
const addCustomerCapacityDialogOpen = ref(false)
const addTagCapacityDialogOpen = ref(false)
const capacitiesStore = useCapacitiesStore()
const activeCapacitySet = toRef(capacitiesStore, 'activeCapacitySet')
const {
  customerSets: customerCapacitySets,
  tagSets: tagCapacitySets,
  fleetSet: globalCapacitySet,
} = storeToRefs(capacitiesStore)

const activeCustomerIDQuery = useRouteQuery<string | undefined>('customer_id')
const activeTagQuery = useRouteQuery<string | undefined>('tag')
const activeCustomerID = computed(() => {
  if (activeCapacitySet.value) {
    return activeCapacitySet.value.customerID
  } else {
    return null
  }
})
const activeContainerTag = computed(() => {
  if (activeCapacitySet.value) {
    return activeCapacitySet.value.containerTag
  } else {
    return null
  }
})
watch(activeCustomerID, (customerID) => {
  if (customerID !== null) {
    activeCustomerIDQuery.value = customerID.toString()
  } else {
    activeCustomerIDQuery.value = undefined
  }
})
watch(activeContainerTag, (containerTag) => {
  if (containerTag !== null) {
    activeTagQuery.value = containerTag
  } else {
    activeTagQuery.value = undefined
  }
})
const loading = ref(false)
const getCapacitySetLabel = useGetCapacitySetLabel()

onMounted(() => {
  loading.value = true
  // Need to grab these before they might be changed by our "default" Capacity Set selection
  const customerIDToSelect = activeCustomerIDQuery.value
    ? parseInt(activeCustomerIDQuery.value)
    : undefined
  const tagToSelect = activeTagQuery.value
  capacitiesStore
    .loadBucketsIfNotLoaded()
    .then(() => {
      if (customerIDToSelect) {
        const capacitySet = capacitiesStore.customerSets.get(customerIDToSelect)
        if (capacitySet) {
          capacitiesStore.setActiveCapacitySet(capacitySet)
        }
      }
      if (tagToSelect) {
        const capacitySet = capacitiesStore.tagSets.get(tagToSelect)
        if (capacitySet) {
          capacitiesStore.setActiveCapacitySet(capacitySet)
        }
      }
    })
    .finally(() => {
      loading.value = false
    })
})

function removeCapacitySet(set: CapacitySet) {
  const customerName = getCapacitySetLabel(set)
  const title = `Remove '${customerName}' Capacity Constraints?`
  let message = ''
  if (
    (customerCapacitySets.value && customerCapacitySets.value.size > 1) ||
    (tagCapacitySets.value && tagCapacitySets.value.size > 1)
  ) {
    message = 'Your other capacity sets will remain unchanged.'
  }
  confirmBox({
    title,
    message,
    confirmButtonText: 'Remove',
    confirmButtonClass: 'el-button--danger',
  }).then(() => {
    // we need to set customer to disable to either an int or undefined, but never a string
    capacitiesStore.deleteCapacitySet(set)
  })
}

onBeforeRouteLeave((to, from, next) => {
  if (capacitiesStore.activeCapacitySetModified && capacitiesStore.setup) {
    const answer = ElMessageBox.confirm(
      'To save your changes, click "Stay on Page", then click "Save Changes".',
      'Unsaved Changes!',
      {
        confirmButtonText: 'Discard Changes and Leave',
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: 'Stay on Page',
        type: 'error',
      }
    ).then(() => {
      if (!answer) return next(false)
      else return next()
    })
  } else {
    next()
  }
})
function addCapacitySet() {
  addCustomerCapacityDialogOpen.value = true
  addTagCapacityDialogOpen.value = true
}
function generateGlobalCapacity() {
  capacitiesStore.regenerateBuckets(
    120,
    { customer: null, containerTag: null },
    false
  )
}
function saveCapacitySet() {
  if (activeCapacitySet.value) {
    capacitiesStore.saveCapacitySet(activeCapacitySet.value)
  }
}
</script>

<template>
  <header class="text-center mb-8">
    <h1 class="text-xl font-bold">
      Capacity Management
      <Help title="What is Capacity Management?" class="inline text-gray-700">
        <p>
          Capacity Management helps Auto Book as well as manual booking select
          appointments for times where your organization has adequate capacity.
        </p>
        <p>
          You define how many appointments you want for different times of day
          and days of the week, and Auto Book will only book appointments for
          times when there is remaining capacity. All import appointments are
          considered when Auto Book determines remaining capacity.
        </p>
        <p>
          Capacity Management also provides warnings to users manually booking
          about times over capacity.
        </p>
        <p>
          You can set "Fleet" capacity and/or capacity for specific customers.
          Customer capacity can either be "Receiving" which is constrains
          <em>in addition to</em> Fleet capacity, or "Dedicated" which is
          <em>separate from</em> Fleet capacity.
        </p>
      </Help>
    </h1>
  </header>
  <div v-loading="capacitiesStore.loading" class="center">
    <!-- Selector and controls -->
    <el-form label-position="left">
      <div class="text-center">
        <div class="flex justify-center">
          <div class="button-container">
            <el-button
              v-if="!globalCapacitySet"
              v-tooltip="
                `We recommend setting global capacity constraints in most scenarios`
              "
              type="success"
              data-testid="add-customer-capacity-button"
              class="mt-18px mr-1"
              @click.stop="generateGlobalCapacity()"
            >
              <i-gridicons:add-outline class="mr-1" />Add Fleet Capacity
            </el-button>
            <CapacitySetButton
              v-if="globalCapacitySet"
              :capacity-set="globalCapacitySet"
            />
            <CapacitySetButton
              v-for="capacitySet in customerCapacitySets.values()"
              :key="capacitySet.customerID!"
              :capacity-set="capacitySet"
            />
            <CapacitySetButton
              v-for="capacitySet in tagCapacitySets.values()"
              :key="capacitySet.containerTag!"
              :capacity-set="capacitySet"
            />
            <el-button
              v-tooltip="
                `Add a new set of capacity constraints for a specific customer`
              "
              data-testid="add-customer-capacity-button"
              circle
              :icon="Plus"
              class="mt-18px"
              @click.stop="addCapacitySet()"
            />
          </div>
        </div>
        <div
          v-if="activeCapacitySet"
          class="text-sm text-gray-700 max-w-250 mx-auto flex"
        >
          <p class="ml-160px mr-1">
            <!-- Fleet Capacity -->
            <template v-if="activeCapacitySet.customerID === null && activeCapacitySet.containerTag===null">
              <strong>Fleet Capacity</strong> defines how many appointments
              (excluding appointments for customers with dedicated capacity)
              your entire fleet can handle. Previously known as "Global"
              capacity
            </template>
            <!-- Dedicated Fleet Capacity -->
            <template v-else-if="activeCapacitySet.dedicated">
              <strong>Dedicated</strong> Fleet Capacity defines how many
              appointments your fleet dedicated to serving
              <strong>{{ getCapacitySetLabel(activeCapacitySet) }}</strong>
              can handle. These constraints are <em>separate from</em> your
              "global" Fleet Capacity definitions.
            </template>
            <!-- Receiving Capacity -->
            <template v-else>
              <strong>Receiving Capacity</strong>
              for
              <strong>{{ getCapacitySetLabel(activeCapacitySet) }}</strong>
              defines how many appointments this customer can handle. These
              constraints are <em>in addition to</em> any Fleet Capacity
              definitions
            </template>
          </p>
          <div class="capacity-set-buttons">
            <el-button
              v-if="
                activeCapacitySet.customerID || activeCapacitySet.containerTag
              "
              v-tooltip="
                activeCapacitySet.dedicated
                  ? `Receiving capacity is in addition to Fleet Capacity`
                  : `Dedicated capacity is separate from Fleet Capacity, giving this customer totally dedicated capacity`
              "
              type="primary"
              size="small"
              plain
              class="mb-1"
              @click="
                activeCapacitySet.dedicated = !activeCapacitySet.dedicated
              "
            >
              <template v-if="activeCapacitySet.dedicated">
                Make Receiving
              </template>
              <template v-else> Make Dedicated</template>
            </el-button>
            <el-button
              type="danger"
              size="small"
              plain
              @click.stop="removeCapacitySet(activeCapacitySet)"
            >
              <el-icon name="el-icon-delete">
                <i-mdi:delete />
              </el-icon>
              Remove
              {{ getCapacitySetLabel(activeCapacitySet) }} Capacity Set
            </el-button>
          </div>
        </div>
      </div>
    </el-form>
    <!-- Chart, controls and manual editor -->

    <div v-if="activeCapacitySet" class="flex mt-5 pl-14">
      <el-form-item
        v-if="activeCapacitySet"
        size="small"
        style="margin-bottom: 0px"
      >
        <template #label>
          <Help
            title="Capacity Bucket Widths"
            class="text-gray-600 mr-2px mb-2px"
          >
            <p>
              You can select different "bucket widths" for your capacity
              constraint definitions. A smaller bucket width gives you more
              precision on how many appointments you want for a time. A larger
              time bucket will give Auto Book more flexibility in how it selects
              appointments.
            </p>
            <p>
              Keep in mind that Auto Book follows capacity constraints very
              literally. If you set capacity to 10 appointments from 7AM to 8AM
              and 10 from 8AM to 9AM, Auto Book will book
              <strong>MAX</strong> 10 for each time bucket. If no appointments
              are booked for 7AM, Auto Book will still book max 10 appointments
              for 8AM. It will not "make up" the difference.
            </p>
          </Help>
          Bucket Width:
        </template>
        <BucketSizeMinutesSelector
          :model-value="activeCapacitySet.bucketMinutes"
          size="small"
          class="max-w-24 min-w-20"
          @update:model-value="
            (bucketSize: number) => {
              capacitiesStore.regenerateBuckets(
                bucketSize,
                activeCapacitySet!,
                activeCapacitySet!.dedicated
              )
            }
          "
        />
      </el-form-item>
      <el-button
        v-if="capacitiesStore.activeCapacitySetModified"
        v-loading="capacitiesStore.saving || capacitiesStore.loading"
        type="success"
        size="small"
        class="ml-2"
        data-testid="save-capacity-changes-button"
        @click="saveCapacitySet()"
      >
        Save Changes
      </el-button>
    </div>
    <CapacityChart
      v-if="activeCapacitySet && activeCapacitySet.buckets.length > 0"
      :buckets="activeCapacitySet.buckets"
      :bucket-minutes="activeCapacitySet.bucketMinutes"
      :customer-id="activeCustomerID || undefined"
      :container-tag="activeContainerTag || undefined"
      :dedicated-capacity-customer-ids="
        capacitiesStore.dedicatedCapacitySetIds.length === 0
          ? undefined
          : capacitiesStore.dedicatedCapacitySetIds
      "
      :dedicated-capacity-tags="
        capacitiesStore.dedicatedCapacitySetTags.length === 0
          ? undefined
          : capacitiesStore.dedicatedCapacitySetTags
      "
    />
    <ManualCapacityEditor
      v-if="activeCapacitySet"
      :buckets="activeCapacitySet.buckets"
      class="mt-4"
    />
    <!-- Dialogs -->
    <AddCapacitySetDialog v-model="addCustomerCapacityDialogOpen" />
  </div>
</template>

<style lang="scss">
.button-container {
  display: flex;
  overflow-x: auto;
  max-width: 100%;
  padding: 18px;
  max-width: 80vw;
  @apply flex flex-wrap justify-center;
}
p {
  margin: 0;
  text-indent: 0;
}
.capacity-set-buttons button.el-button {
  margin-left: 0px;
  @apply min-w-50;
}
</style>
