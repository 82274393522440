<script lang="ts" setup>
import { DateTime } from 'luxon'
import type { CategoryKeyWithTimestamps } from '~/stores/returnRulesLatest'
import { getTerminalLabel } from '~/constants'
import { useEmptyAppointmentSlotsStore } from '~/stores/emptyAppointmentSlots'
import { useLuxonTimeAgo } from '~/compositions/timeAgo'

const props = defineProps<{
  row: CategoryKeyWithTimestamps
  previousRow: CategoryKeyWithTimestamps | null
  containerCount: number
  showShippingLine: boolean
  showTerminal: boolean
  showContainerType: boolean
}>()
const emptyAppointmentSlotsStore = useEmptyAppointmentSlotsStore()
const lastChecked = useLuxonTimeAgo(props.row.lastChecked)
const STALE_CUTOFF_MINUTES = 10

const rulesAreStale = ref(false)
function updateRulesAreStale() {
  const staleTime = props.row.lastChecked.plus({
    minutes: STALE_CUTOFF_MINUTES,
  })
  setTimeout(updateRulesAreStale, 1000 * 60 * STALE_CUTOFF_MINUTES)
  rulesAreStale.value = staleTime < DateTime.now()
}
updateRulesAreStale()
</script>

<template>
  <!-- Shipping Line -->
  <template v-if="props.showShippingLine">
    <th
      v-if="!previousRow || row.shippingLine !== previousRow.shippingLine"
      class="label"
    >
      {{ row.shippingLine }}
    </th>
    <th v-else class="label !border-top-0"></th>
  </template>
  <!-- Terminal -->
  <template v-if="showTerminal">
    <th
      v-if="
        !previousRow ||
        row.terminal !== previousRow.terminal ||
        row.shippingLine !== previousRow.shippingLine
      "
      class="label"
    >
      <span class="terminal-name-label">{{
        getTerminalLabel(row.terminal)
      }}</span>
      <div v-if="containerCount > 0" class="font-normal text-gray-400">
        <i-mdi:calendar-check class="align-text-bottom" />
        {{ containerCount }}
        booked
      </div>
      <div
        v-if="emptyAppointmentSlotsStore.getErrors(row)"
        v-tooltip="emptyAppointmentSlotsStore.getErrors(row)"
        class="text-red-700 text-xs font-normal"
      >
        Error loading appts
      </div>
      <div
        v-if="rulesAreStale"
        v-tooltip="`Rules were checked ${lastChecked}`"
        class="text-red-700 text-xs font-normal"
      >
        Stale rules
      </div>
      <!-- <div class="font-normal text-xs">
        {{ lastChecked }}
      </div> -->
    </th>
    <th v-else class="label !border-top-0"></th>
  </template>
  <!-- Container Type -->
  <template v-if="showContainerType">
    <th
      v-if="
        !previousRow ||
        row.containerType !== previousRow.containerType ||
        row.shippingLine !== previousRow.shippingLine ||
        row.terminal !== previousRow.terminal
      "
      class="label"
    >
      {{ row.containerType }}
    </th>
    <th v-else class="label !border-top-0"></th>
  </template>
</template>
~/stores/returnRulesLatest
