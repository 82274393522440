<script lang="ts" setup>
import type { Ref } from 'vue'
import type { FormInstance } from 'element-plus/es/components/form'
import type { FormValidateCallback, FormValidationResult } from 'element-plus'
import type { AppointmentSlot } from '~/models/appointmentSlot'
import { formatDT } from '~/utils'
import {
  TERMINALS_REQUIRING_MASTER_BL,
  TERMINALS_USING_OWN_CHASSIS_DESIGNATION,
  getTerminalLabel,
} from '~/constants'
import type { Container } from '~/models/containers'
import type { GETAutoBookRequest, TerminalName } from '~/services/apiClient'
import { AutoBookRequestStatus, PortalName } from '~/services/apiClient'
import { useUserStore } from '~/stores/user'
import { useLicensePlateRules } from '~/compositions/useLicensePlateRules'
import type { Appointment } from '~/models/appointments'
import LicensePlateSelector from '~/components/inputs/LicensePlateSelector.vue'

const props = defineProps<{
  container: Container
  appointmentSlot?: AppointmentSlot
  inline?: boolean
  existingAutoBookRequest?: GETAutoBookRequest
}>()
const slot = toRef(props, 'appointmentSlot')
const existingAutoBookRequest = toRef(props, 'existingAutoBookRequest')
const userStore = useUserStore()

const container = computed(() => props.container)
if (props.container.terminal === null) {
  throw new Error(`Container ${props.container.number} has no terminal`)
}
const appointmentSlotWindowStart = computed(() => {
  return props.appointmentSlot?.window_start
})
// We should not be able to get here if there is no terminal on the container
const terminal = toRef(props.container, 'terminal') as Ref<TerminalName>
const existingAppointment = toRef(
  props.container,
  'booked_appointment'
) as Ref<Appointment | null>
const { usesLicensePlate, requiresLicensePlate, shouldPrefillLicensePlate } =
  useLicensePlateRules({
    terminal,
    appointmentWindowStart: appointmentSlotWindowStart,
    forEmptyBooking: false,
    existingAppointment,
  })
const requiresOwnChassisSelector = computed(() =>
  TERMINALS_USING_OWN_CHASSIS_DESIGNATION.has(terminal.value)
)
const ownChassis = toRef(userStore, 'useOwnChassis')
const form = reactive({
  masterBL: '',
  licensePlate: '',
})

function prefillExistingLicensePlate() {
  if (!usesLicensePlate.value) {
    form.licensePlate = ''
  } else if (
    props.existingAutoBookRequest &&
    props.existingAutoBookRequest.settings.license_plate
  ) {
    form.licensePlate = props.existingAutoBookRequest.settings.license_plate
  }
}

function prefillExistingOwnChassis() {
  if (requiresOwnChassisSelector && props.existingAutoBookRequest) {
    ownChassis.value =
      props.existingAutoBookRequest.settings.own_chassis || false
  }
}
function prefillMasterBL() {
  if (
    props.existingAutoBookRequest &&
    props.existingAutoBookRequest.settings.master_bill_of_lading
  ) {
    form.masterBL = props.existingAutoBookRequest.settings.master_bill_of_lading
    return
  }
  if (
    container.value.import_status &&
    container.value.import_status.master_bill_of_lading
  ) {
    form.masterBL = container.value.import_status.master_bill_of_lading
    return
  }
  for (const importStatus of container.value.import_statuses) {
    if (
      importStatus.master_bill_of_lading &&
      importStatus.source !== PortalName.PortOptimizer
    ) {
      form.masterBL = importStatus.master_bill_of_lading
      return
    }
  }
  // last resort
  if (container.value.master_bill_of_lading) {
    form.masterBL = container.value.master_bill_of_lading
  }
}
watch(
  existingAutoBookRequest,
  (newAutoBookRequestValue: GETAutoBookRequest | undefined) => {
    prefillMasterBL()
    if (newAutoBookRequestValue) {
      prefillExistingLicensePlate()
      prefillExistingOwnChassis()
    }
  },
  { immediate: true }
)
watch(
  container,
  () => {
    if (
      container.value.booked_appointment &&
      usesLicensePlate &&
      container.value.booked_appointment.truck_license_plate_number
    ) {
      form.licensePlate =
        container.value.booked_appointment.truck_license_plate_number
    }
    if (
      container.value.booked_appointment &&
      requiresOwnChassisSelector &&
      container.value.booked_appointment.extra_data &&
      'own_chassis' in container.value.booked_appointment.extra_data &&
      container.value.booked_appointment.extra_data.own_chassis !== null
    ) {
      ownChassis.value = container.value.booked_appointment.extra_data
        .own_chassis as boolean
    }
  },
  { immediate: true }
)

const requiresMasterBL = computed(() =>
  TERMINALS_REQUIRING_MASTER_BL.has(terminal.value)
)
const formRules = reactive({
  masterBL: [
    {
      required: requiresMasterBL.value,
      message: 'Required',
      trigger: 'change',
    },
  ],
  licensePlate: [
    {
      required: requiresLicensePlate.value,
      message: 'Required',
      trigger: 'change',
    },
  ],
})

const formRef = ref<FormInstance>()

function getValues(): {
  masterBL: string
  licensePlate: string
  ownChassis: boolean | undefined
} {
  const formToReturn = {
    masterBL: form.masterBL,
    licensePlate: form.licensePlate,
    ownChassis: requiresOwnChassisSelector.value ? ownChassis.value : undefined,
  }
  return formToReturn
}
function validate(callback?: FormValidateCallback): FormValidationResult {
  return formRef.value!.validate(callback)
}
defineExpose({ getValues, validate, containerNumber: container.value.number })

const statusColor = computed(() => {
  switch (props.container.auto_book_request_status) {
    case AutoBookRequestStatus.Requested:
      return 'green'
    case AutoBookRequestStatus.Complete:
      return 'gray'
    case AutoBookRequestStatus.Failed:
      return 'red'
    case AutoBookRequestStatus.InProgress:
      return 'yellow'
    case AutoBookRequestStatus.Cancelled:
      return 'grey'
    default:
      return 'black'
  }
})
</script>

<template>
  <el-form
    ref="formRef"
    :model="form"
    :rules="formRules"
    :label-width="inline ? undefined : 'auto'"
    :inline="inline"
    size="small"
    class="booking-dialog-form"
  >
    <el-form-item label="Container #:">
      {{ props.container.number }}
    </el-form-item>
    <el-form-item label="Terminal:" class="min-w-100px">
      {{ getTerminalLabel(terminal) }}
    </el-form-item>
    <el-form-item v-if="slot" label="Time:">
      {{ formatDT(slot.window_start) }}
      <template v-if="slot.window_end">
        - {{ formatDT(slot.window_end) }}
      </template>
    </el-form-item>
    <el-form-item
      v-if="requiresMasterBL"
      label="Master BL #:"
      prop="masterBL"
      :class="{ 'max-w-200px': inline }"
    >
      <el-input v-model="form.masterBL" placeholder="Enter Here">
        <template #prefix>
          <i-la:scroll />
        </template>
      </el-input>
    </el-form-item>
    <el-form-item
      v-if="usesLicensePlate"
      data-testid="license-plate-form-item"
      label="License plate #:"
      prop="licensePlate"
      :class="{ 'max-w-380px': inline }"
    >
      <LicensePlateSelector
        v-if="!container.booked_appointment"
        v-model="form.licensePlate"
        :terminal="terminal"
        :auto-select-value="
          shouldPrefillLicensePlate && !container.booked_appointment
        "
        :disabled="Boolean(container.booked_appointment)"
        :class="{ 'w-full': !inline }"
        :show-icon="!inline"
      />
      <span v-else>
        {{ form.licensePlate }}
      </span>
    </el-form-item>
    <el-form-item
      v-if="requiresOwnChassisSelector"
      label="Chassis:"
      prop="ownChassis"
      :class="{ 'max-w-380px': inline }"
    >
      <el-switch
        v-model="ownChassis"
        inline-prompt
        active-color="#13ce66"
        inactive-color="#ff4949"
        active-text="Own"
        inactive-text="Pool"
        :disabled="Boolean(container.booked_appointment)"
      />
    </el-form-item>
    <el-form-item
      v-if="container.auto_book_request_status"
      label="Auto-book Status:"
    >
      <span
        :style="{
          color: statusColor,
        }"
      >
        {{ container.auto_book_request_status }}
      </span>
    </el-form-item>
  </el-form>
</template>

<style lang="scss">
.booking-dialog-form {
  .el-form-item__label {
    font-weight: bold;
  }
  .el-form-item {
    margin-right: 15px;
  }
  margin-bottom: 8px;
  .el-form-item--small {
    // height: 42px;
    margin-bottom: 0px;
  }
}
</style>
