import { acceptHMRUpdate, defineStore } from 'pinia'
import type { SoketiEvent } from '#compositions/configureSoketi'
import { TerminalGateSchedule } from '~/models/gateSchedules'
import type { GateScheduleUpdatedEvent } from '~/services/apiClient'
import {
  EventName,
  GateSchedulesApi,
  WebsocketChannel,
} from '~/services/apiClient'
import { useSoketi } from '#compositions/configureSoketi'
export const useGateSchedulesStore = defineStore('gate-schedules', () => {
  const gate_schedules = ref([] as TerminalGateSchedule[])
  const loading = ref(false)
  const soketi = useSoketi()
  soketi.bind(
    WebsocketChannel.GateSchedule,
    EventName.GateSchedulesUpdated,
    handleGateScheduleEvent
  )
  function handleGateScheduleEvent(event: SoketiEvent) {
    const scheduleEvent = event as GateScheduleUpdatedEvent
    const entries = scheduleEvent.entries
    const terminal = event.terminal
    const screenshot_url = event.screenshot_url
    // remove the terminal schedule for this terminal

    gate_schedules.value = gate_schedules.value.filter(
      (schedule) => schedule.terminal !== terminal
    )
    // add the new schedule
    gate_schedules.value.push(
      new TerminalGateSchedule({
        terminal,
        screenshot_url,
        entries,
      })
    )
  }

  function load(): Promise<void> {
    const api = new GateSchedulesApi()
    loading.value = true
    return api
      .getLatestGateSchedulesGateSchedulesLatestGet()
      .then((response) => {
        gate_schedules.value = response.data.map(
          (schedule) => new TerminalGateSchedule(schedule)
        )
      })
      .finally(() => {
        loading.value = false
      })
  }
  return {
    load,
    gate_schedules,
    loading,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useGateSchedulesStore, import.meta.hot)
  )
}
