import type { DateTime } from 'luxon'
import type { BaseAppointmentSlot } from '~/models/appointmentSlot'
const BUCKET_HOURS = 3
const BUCKET_STARTS: number[] = []
for (let hour = 0; hour < 24; hour++) {
  if (hour % BUCKET_HOURS === 0) BUCKET_STARTS.push(hour)
}
export const BUCKET_INFOS: { description: string }[] = []

for (const bucketStart of BUCKET_STARTS) {
  const bucketEnd = bucketStart + BUCKET_HOURS

  BUCKET_INFOS.push({
    description: `${bucketStart}-${bucketEnd}`,
  })
}

export class Bucket {
  hour: number
  slots: BaseAppointmentSlot[]
  bucketStart: DateTime
  bucketEnd: DateTime
  hasBookedAppointment: boolean
  constructor(bucketStart: DateTime, bucketEnd: DateTime) {
    this.bucketStart = bucketStart
    this.hour = bucketStart.hour
    this.slots = []
    this.bucketEnd = bucketEnd
    this.hasBookedAppointment = false
  }

  get numSlots(): number {
    return this.slots.reduce(
      (prev, slot) =>
        prev +
        (slot.num_appointments_available === undefined ||
        slot.num_appointments_available === null
          ? 1
          : slot.num_appointments_available),
      0
    )
  }

  get numSlotsIsLowerBound(): boolean {
    return this.slots.some(
      (slot) =>
        slot.num_appointments_available === undefined ||
        slot.num_appointments_available === null
    )
  }

  get hasAvailability(): boolean {
    return this.numSlots > 0
  }

  addSlot(slot: BaseAppointmentSlot) {
    this.slots.push(slot)
    if (slot.booked_appointment) {
      this.hasBookedAppointment = true
    }
  }
}

export function bucketAppointments(
  slots: BaseAppointmentSlot[],
  date: DateTime
): Bucket[] {
  const buckets = new Map<Number, Bucket>()
  for (const startHour of BUCKET_STARTS) {
    const bucketStart = date.set({ hour: startHour })
    const bucketEnd = bucketStart.plus({ hours: BUCKET_HOURS })
    buckets.set(startHour, new Bucket(bucketStart, bucketEnd))
  }
  for (const slot of slots) {
    const hour = slot.window_start.hour
    const hourBucket = Math.floor(hour / BUCKET_HOURS) * BUCKET_HOURS
    buckets.get(hourBucket)!.addSlot(slot)
  }
  for (const bucket of buckets.values()) {
    bucket.slots.sort((a, b) => a.window_start.diff(b.window_start).valueOf())
  }
  return [...buckets.values()]
}
