export const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/index.vue'),
    /* no children */
    meta: {
      "layout": "no_sidebar"
    }
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/[...all].vue'),
    /* no children */
    meta: {
      "layout": 404
    }
  },
  {
    path: '/accept_invite',
    name: 'accept_invite',
    component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/accept_invite.vue'),
    /* no children */
    meta: {
      "layout": "no_sidebar"
    }
  },
  {
    path: '/book-appointments',
    name: 'book-appointments',
    component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/book-appointments.vue'),
    /* no children */
    meta: {
      "auth": true
    }
  },
  {
    path: '/capacity',
    name: 'capacity',
    component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/capacity.vue'),
    /* no children */
    meta: {
      "auth": true
    }
  },
  {
    path: '/containers',
    name: 'containers',
    component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/containers.vue'),
    /* no children */
    meta: {
      "auth": true
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/dashboard.vue'),
    /* no children */
    meta: {
      "auth": true
    }
  },
  {
    path: '/empty_returns',
    name: 'empty-returns',
    component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/empty_returns.vue'),
    /* no children */
    meta: {
      "auth": true,
      "layout": "default"
    }
  },
  {
    path: '/error',
    name: '/error',
    component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/error.vue'),
    /* no children */
  },
  {
    path: '/gate_schedules',
    name: 'gate-schedules',
    component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/gate_schedules.vue'),
    /* no children */
    meta: {
      "layout": "default",
      "auth": true
    }
  },
  {
    path: '/la_lb_gate_cameras_iframe',
    name: 'la-lb-cameras',
    component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/la_lb_gate_cameras_iframe.vue'),
    /* no children */
    meta: {
      "layout": "blank"
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/login.vue'),
    /* no children */
    meta: {
      "layout": "no_sidebar"
    }
  },
  {
    path: '/reset_password',
    name: '/reset_password',
    component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/reset_password.vue'),
    /* no children */
    meta: {
      "layout": "no_sidebar"
    }
  },
  {
    path: '/scratch',
    name: '/scratch',
    component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/scratch.vue'),
    /* no children */
  },
  {
    path: '/set_up_company',
    name: 'set_up_company',
    component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/set_up_company.vue'),
    /* no children */
    meta: {
      "layout": "no_sidebar"
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/settings.vue'),
    /* no children */
    meta: {
      "auth": true
    }
  },
  {
    path: '/sign_up',
    name: 'sign_up',
    component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/sign_up.vue'),
    /* no children */
    meta: {
      "layout": "no_sidebar"
    }
  },
  {
    path: '/terminal_cameras',
    /* internal name: '/terminal_cameras' */
    /* no component */
    children: [
      {
        path: '',
        name: 'cameras-home',
        component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/terminal_cameras/index.vue'),
        /* no children */
        meta: {
          "layout": "no_sidebar"
        }
      },
      {
        path: ':terminalName',
        name: 'specific-terminal',
        component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/terminal_cameras/[terminalName].vue'),
        /* no children */
        meta: {
          "layout": "no_sidebar"
        }
      }
    ],
  },
  {
    path: '/trigger_reset_password',
    name: 'trigger_reset_password',
    component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/trigger_reset_password.vue'),
    /* no children */
    meta: {
      "layout": "no_sidebar"
    }
  },
  {
    path: '/trucks',
    name: 'trucks',
    component: () => import('/__w/dray_dog_frontend/dray_dog_frontend/src/pages/trucks.vue'),
    /* no children */
    meta: {
      "auth": true,
      "layout": "default"
    }
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

