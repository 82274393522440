<script lang="ts" setup>
import { DateTime } from 'luxon'
import type { BookEvent } from './events'
import type { BaseAppointmentSlot } from '~/models/appointmentSlot'
import { bucketAppointments } from '~/logic/appointmentBucketing'
import type { Container } from '~/models/containers'
import { useUserStore } from '~/stores/user'

const props = defineProps<{
  slots: BaseAppointmentSlot[]
  date: DateTime
}>()

const emit = defineEmits({
  book: (event: BookEvent) => true,
})

const userStore = useUserStore()

const showHideAppt = computed(() => {
  return userStore.getShowHideImportAppt
})

const container = inject<Container | null>('container', null)
const bookedAppointment = computed(() => {
  if (!container) return null
  return container.booked_appointment
})
const slots = toRef(props, 'slots')
const slotBuckets = computed(() => {
  return bucketAppointments(slots.value, props.date)
})
const dayDescription = computed(() => {
  return props.date.toFormat('ccc M/dd')
})
const isLFD = computed(() => {
  if (!container) return false
  if (!container.last_free_day) return false
  return container.last_free_day.toISODate() === props.date.toISODate()
})
const now = ref(DateTime.now())
</script>

<template>
  <tr class="mb-4">
    <th class="text-xs font-semibold text-right pb-9">
      <ColoredBadge
        v-if="isLFD"
        type="warning"
        size="default"
        class="shadow mr-1"
      >
        LFD
      </ColoredBadge>

      <span class="pb-6">
        {{ dayDescription }}
      </span>
    </th>

    <td
      v-for="bucket in slotBuckets.values()"
      :key="bucket.hour"
      class="py-8px text-center"
    >
      <AppointmentSlotsBucketButton
        v-if="
          bucket.bucketEnd > now.minus({ minutes: 30 }) ||
          bucket.hasBookedAppointment
        "
        :bucket="bucket"
        :description="dayDescription"
        :booked-appointment="bookedAppointment"
        :show-hide-appt="showHideAppt"
        @book="emit('book', $event)"
      />
    </td>
  </tr>
</template>
