<script lang="ts" setup>
import type { HourGroup } from './types'
import { appointmentCountDescription } from './utils'

const props = defineProps<{ hourGroup: HourGroup }>()

function hourToMilitaryTime(hour: number, minute = 0): string {
  return (
    hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0')
  )
}
const tooltip = computed(() => {
  return `${appointmentCountDescription(
    props.hourGroup
  )} scheduled from ${hourToMilitaryTime(
    props.hourGroup.hour
  )} to ${hourToMilitaryTime(props.hourGroup.hour, 59)}`
})
</script>

<template>
  <BaseAppointmentHeader :tooltip="tooltip" :is-sub-header="true">
    <template #left>
      {{ hourToMilitaryTime(hourGroup.hour) }}
    </template>
    <template #right>
      <ColoredBadge
        v-for="capacityBucket in hourGroup.overCapacityBuckets"
        :key="capacityBucket.bucket.key"
        v-tooltip="
          `${capacityBucket.bucket.setDescription} capacity for ${capacityBucket.bucket.timeDescription} is ${capacityBucket.bucket.maxAppointments} import appointments and there are ${capacityBucket.numScheduledAppointments} scheduled for this period`
        "
        type="danger"
        effect="dark"
        class="ml-2"
      >
        <i-mdi:alert class="align-text-bottom" />
        Over
        <em>{{ capacityBucket.bucket.setDescription }}</em>
        capacity by
        {{ capacityBucket.remainingCapacity * -1 }}
        appointment<template v-if="capacityBucket.remainingCapacity !== -1"
          >s</template
        >
      </ColoredBadge>
      <AppointmentCounts :group="hourGroup" />
    </template>
  </BaseAppointmentHeader>
</template>
