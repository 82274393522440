<script lang="ts" setup>
import { useUserStore } from '../stores/user'

const emit = defineEmits<{
  (e: 'toggleShowHide', value: boolean | undefined): void
}>()

const user = useUserStore()

// Set storage to true by default then update based on function
const storageShowAppt: Ref<boolean | undefined> = useStorage(
  'showAppt',
  true,
  localStorage
)

const showImportApp: Ref<boolean | undefined | null> = ref(null)

const onClickToggleView = (): void => {
  storageShowAppt.value = !storageShowAppt.value
  user.setShowHideImportAppt(storageShowAppt.value)
}

const buttonText: ComputedRef<string> = computed(() => {
  return showImportApp.value ? 'Hide Import Appt' : 'Show Import Appt'
})

watch(storageShowAppt, () => {
  showImportApp.value = storageShowAppt.value
})

onMounted(() => {
  showImportApp.value = storageShowAppt.value
  user.setShowHideImportAppt(storageShowAppt.value)
})
</script>

<template>
  <div>
    <el-button
      type="primary"
      plain
      size="small"
      class="fs-refresh-import-status-button"
      @click="onClickToggleView"
    >
      <i-mdi:eye v-if="!showImportApp" class="mr-1" />
      <i-mdi:eye-off v-else class="mr-1" />
      {{ buttonText }}
    </el-button>
  </div>
</template>
