<script lang="ts" setup>
import { useContainerStore } from '~/stores/containers'
import { cleanRoute } from '~/utils'
import { useContainerFilters } from '~/compositions/containerFilters'
import { ContainerWatchState } from '~/services/apiClient'
import {
  CONTAINER_ROW_MIN_WIDTH_PIXELS,
  contentAreaHeightInjectionKey,
} from '~/constants'

useHead({
  title: 'Dray Dog - Containers',
})
const route = useRoute()
const router = useRouter()
const containerStore = useContainerStore()
const { statusFilter } = useContainerFilters()
const focusedContainer = toRef(containerStore, 'focusedContainer')
const preLoading = toRef(containerStore, 'preLoading')
const containers = toRef(containerStore, 'containers')

// Pre-select container (for page reload/one container result)
watch(containers, () => {
  if (route.query.selected_container) {
    const selectedContainer = route.query.selected_container as string
    const container = containerStore.containerLookup.get(selectedContainer)
    if (container) {
      focusedContainer.value = container
    }
  } else if (containerStore.containers.length === 1) {
    focusedContainer.value = containerStore.containers[0]
  }
})
watch(focusedContainer, () => {
  let containerNumber
  if (focusedContainer.value) {
    containerNumber = focusedContainer.value.number
  } else {
    containerNumber = null
  }
  router.push({
    query: cleanRoute({
      ...route.query,
      selected_container: containerNumber,
    }),
  })
})

// Heights
const containerFiltersElement = ref<HTMLElement | null>(null)
const containerFiltersSize = useElementSize(containerFiltersElement)
const actionToolbarElement = ref<HTMLElement | null>(null)

const contentAreaHeight = inject(contentAreaHeightInjectionKey) as Ref<number>
const ACTION_TOOLBAR_HEIGHT = 50
const gutterSize = 6
const commonFudgeFactor = 24
const containersScrollAreaHeight = computed(() => {
  return (
    contentAreaHeight.value -
    containerFiltersSize.height.value -
    ACTION_TOOLBAR_HEIGHT -
    gutterSize * 3 -
    commonFudgeFactor -
    16 // Paddings and such
  )
})
const detailsAreaHeight = computed(() => {
  return (
    contentAreaHeight.value -
    containerFiltersSize.height.value -
    gutterSize * 2 -
    commonFudgeFactor
  )
})
</script>

<template>
  <div class="flex flex-col h-full">
    <!-- Filters -->
    <ContainerFilters
      ref="containerFiltersElement"
      class="flex-shrink-0 mb-6px"
    />
    <TwoPaneLayout>
      <template #left>
        <div class="h-full">
          <Card
            class="mb-6px px-4 pr-2 py-1"
            :style="{ height: `${ACTION_TOOLBAR_HEIGHT}px` }"
          >
            <ActionToolbar ref="actionToolbarElement" />
          </Card>
          <Card class="pt-2">
            <!-- Results -->
            <el-scrollbar
              id="containers-list-scroll-area"
              v-loading="preLoading"
              :max-height="containersScrollAreaHeight"
              class="p-2 pt-0 relative"
            >
              <!-- Because we use a fixed height for virtual scrolling, it's important
              to set a minimum width so that overflow does not create rows taller than
              they should be -->
              <div
                :style="{
                  minHeight: `200px`,
                  minWidth: `${CONTAINER_ROW_MIN_WIDTH_PIXELS}px`,
                }"
              >
                <ContainersByShift
                  v-if="
                    statusFilter?.states.includes(
                      ContainerWatchState.ImportApptBooked
                    )
                  "
                  :containers="containers"
                />
                <ContainersList v-else :containers="containers" />

                <NoContainersDescription
                  v-if="containers.length === 0 && !preLoading"
                />
              </div>
            </el-scrollbar>
          </Card>
        </div>
      </template>
      <template #right>
        <Card v-if="focusedContainer">
          <el-scrollbar :max-height="detailsAreaHeight">
            <ContainerDetails
              :key="focusedContainer.number"
              :container="focusedContainer"
            />
          </el-scrollbar>
        </Card>
        <EmptyRightPane v-else text="Click a row to view container details" />
      </template>
    </TwoPaneLayout>
  </div>
</template>

<route lang="yaml">
meta:
  auth: true
name: containers
</route>
