<script lang="ts" setup>
import { useRouteQuery } from '@vueuse/router'
import { DateTime } from 'luxon'
import { AppointmentStatus, TerminalName } from '~/services/apiClient'

import { isValidTerminalName } from '~/constants'
import { useUserStore } from '~/stores/user'
import { useAppointmentWithContainerInfoStore } from '~/stores/appointmentsWithContainerInfo'
import { useMakeLineAndTypeDescription } from '~/compositions/useMakeLineAndTypeDescription'
import { useReturnRulesAndFilters } from '~/compositions/useReturnRulesAndFilters'
import useAppointmentsForReturnRules from '~/compositions/useAppointmentsForReturnRules'

useHead({
  title: 'Dray Dog - Empty Containers',
})

const userStore = useUserStore()
enum ViewOption {
  SpecificContainers = 'SpecificContainers',
  ByLineAndType = 'Container',
  ByTerminal = 'Terminal',
}
enum ViewTimeOption {
  Current = 'Current',
  Historical = 'Historical',
}
const appointmentsWithContainerInfoStore =
  useAppointmentWithContainerInfoStore()
function currentTimeDesc() {
  return (
    DateTime.now().toLocaleString(DateTime.DATETIME_MED) +
    ' ' +
    DateTime.now().toFormat('ZZZZ')
  )
}
const searchedContainerNumber = ref<string | null>(null)
const currentTime = ref(currentTimeDesc())
const { shippingLine, containerType } = useReturnRulesAndFilters()
const {
  existingSingleEmptyAppointments,
  existingDualAppointments,
  dualAppointmentOpportunities,
} = useAppointmentsForReturnRules()
setInterval(() => {
  currentTime.value = currentTimeDesc()
}, 60 * 1000)

const viewQuery = useRouteQuery<string | undefined>('view')
const lastUsedViewOption = useLocalStorage<ViewOption | undefined>(
  'last_used_empty_container_view_option',
  undefined,
  {
    serializer: {
      read: (value: string | undefined): ViewOption | undefined => {
        if (isValidViewOption(value)) {
          return value
        } else {
          return undefined
        }
      },
      write: (value: ViewOption | undefined): string => {
        if (isValidViewOption(value)) {
          return value
        } else {
          return ''
        }
      },
    },
  }
)
const viewTimeQuery = useRouteQuery<string | undefined>('view_time')
const terminalQuery = useRouteQuery<string | undefined>('terminal')

function isValidViewOption(value: any): value is ViewOption {
  return Object.values(ViewOption).includes(value)
}
function isValidViewTimeOption(value: any): value is ViewTimeOption {
  return Object.values(ViewTimeOption).includes(value)
}

// Computed properties
const view = computed(() => {
  if (isValidViewOption(viewQuery.value)) {
    return viewQuery.value
  }
  if (lastUsedViewOption.value) {
    return lastUsedViewOption.value
  }
  return ViewOption.ByLineAndType
})
watch(view, (value) => {
  if (view.value && view.value !== lastUsedViewOption.value) {
    lastUsedViewOption.value = view.value
  }
  lastUsedViewOption.value = value
})
const viewTime = computed(() => {
  if (isValidViewTimeOption(viewTimeQuery.value)) {
    return viewTimeQuery.value
  }
  return ViewTimeOption.Current
})
const terminal = computed(() => {
  if (isValidTerminalName(terminalQuery.value)) {
    return terminalQuery.value
  }
})

const { makeLineAndTypeDescription } = useMakeLineAndTypeDescription()

const lineAndTypeDescription = computed(() => {
  if (shippingLine.value && containerType.value) {
    return makeLineAndTypeDescription({
      shipping_line: shippingLine.value,
      container_type: containerType.value,
    })
  } else {
    return null
  }
})

function setView(value: string | boolean | number | undefined) {
  if (typeof value !== 'string') {
    throw new TypeError(
      `Value must be a string but got ${typeof value} (${value})`
    )
  }
  viewQuery.value = value
}
function setTerminal(value: string | boolean | number) {
  if (typeof value !== 'string') {
    throw new TypeError(
      `Value must be a string but got ${typeof value} (${value})`
    )
  }
  terminalQuery.value = value
}

function loadExistingAppointments() {
  if (!shippingLine.value || !containerType.value) {
    appointmentsWithContainerInfoStore.clearAllData()
    return
  }
  appointmentsWithContainerInfoStore.load({
    // We load ALL appointments because we will use the import appointments for dual
    // related features. We filter to the respective types in computed properties
    statuses: [AppointmentStatus.Scheduled],
    // We don't care about old/stale appointments older that have past. It's always a
    // bit tough to pick this threshold. For this case I think we don't want to display
    // stuff that is basically underway because it's a bit late to be cancelling or
    // dualing which are the primary actions of this view
    after: DateTime.now().minus({ hours: 2 }),
    limitToRelevantAppointments: true,
  })
}

watch(
  [view, terminal],
  () => {
    if (view.value === ViewOption.ByTerminal) {
      if (!terminal.value) {
        terminalQuery.value = TerminalName.Apm
      }
    } else {
      terminalQuery.value = undefined
    }
  },
  { immediate: true }
)
onMounted(loadExistingAppointments)
</script>

<template>
  <!-- Form -->
  <Card class="px-4 pt-2 relative">
    <div class="text-gray-600 text-sm absolute right-2 top-2">
      <span class="text-gray-500">Current time:</span>
      <br />
      {{ currentTime }}
    </div>
    <el-form class="flex err-form-controls" label-position="top">
      <!-- View options -->
      <el-form-item label="View by:" class="mr-8">
        <el-radio-group
          :model-value="view"
          size="large"
          @update:model-value="setView($event)"
        >
          <el-radio-button
            v-if="userStore.is_internal_user"
            :label="ViewOption.SpecificContainers"
          >
            <EmptyContainerIcon class="align-middle -mt-3px" />
            Containers
          </el-radio-button>
          <el-radio-button :label="ViewOption.ByLineAndType">
            <VesselIcon class="align-middle -mt-3px" />Line + Type
          </el-radio-button>
          <el-radio-button :label="ViewOption.ByTerminal">
            <i-mdi:pier-crane class="align-middle -mt-3px" />Terminal
          </el-radio-button>
        </el-radio-group>
        <!-- <el-radio-group
          :model-value="viewTime"
          size="large"
          class="ml-2"
          @update:model-value="setViewTime($event)"
        >
          <el-radio-button :label="ViewTimeOption.Current">
            <i-mdi:clock class="align-middle -mt-3px" />
            Current
          </el-radio-button>
          <el-radio-button :label="ViewTimeOption.Historical">
            <i-mdi:history class="align-middle -mt-3px" />
            Historical
          </el-radio-button>
        </el-radio-group> -->
      </el-form-item>
      <!-- By container options -->
      <template v-if="view === ViewOption.ByLineAndType">
        <LineAndTypeSelector
          v-model:shipping-line="shippingLine"
          v-model:container-type="containerType"
          v-model:searched-container-number="searchedContainerNumber"
        />
        <!-- Booked appointments info -->
        <div class="ml-2 text-gray-700 text-lg font-semibold">
          <div class="text-gray-500 text-sm font-normal">
            <i-mdi:calendar-check class="align-text-bottom" />
            Booked empty appointments:
          </div>
          <template
            v-if="
              shippingLine &&
              containerType &&
              !appointmentsWithContainerInfoStore.loading
            "
          >
            <div
              v-tooltip="
                `You've booked ${existingSingleEmptyAppointments.length} single${existingSingleEmptyAppointments.length === 1 ? '' : 's'} empty appointments for ${lineAndTypeDescription}`
              "
              data-testid="single-empty-appointments-count"
            >
              <span class="total-appts-count">{{
                existingSingleEmptyAppointments.length
              }}</span>
              Single{{
                existingSingleEmptyAppointments.length === 1 ? '' : 's'
              }}
              <EmptyReturnIcon class="align-middle -mt-2px" />
            </div>
            <div
              v-tooltip="
                `You've used ${existingDualAppointments.length} of ` +
                `${dualAppointmentOpportunities.length + existingDualAppointments.length} ` +
                `total dual opportunities for ${lineAndTypeDescription}. ` +
                `You have ${dualAppointmentOpportunities.length} additional opportunities to dual`
              "
              data-testid="dual-appointments-count"
            >
              <span class="total-appts-count">{{
                existingDualAppointments.length
              }}</span>
              Duals
              <DualsIcon class="align-middle -mt-3px" />
              <!-- /
              {{
                dualAppointmentOpportunities.length +
                existingDualAppointments.length
              }}
              opportunities -->
            </div>
          </template>
          <span v-else class="text-gray-500">Loading...</span>
        </div>
      </template>
      <div v-if="view === ViewOption.ByTerminal">
        <el-form-item label="Terminal:">
          <ReturnTerminalRadioGroup
            :model-value="terminal"
            @update:model-value="setTerminal($event)"
          />
        </el-form-item>
      </div>
      <!-- Spacer -->
      <div class="flex-1"></div>
    </el-form>
  </Card>
  <!-- Results -->
  <Card class="mt-6px p-0">
    <!--  Specific containers -->
    <div v-if="view === ViewOption.SpecificContainers">
      <h1 class="text-2xl">Coming soon...</h1>
      A list of containers like the main imports/containers page, broken out by
      line, type and customer location
    </div>
    <!-- Current by shipping line + container type -->
    <div
      v-if="
        view === ViewOption.ByLineAndType && viewTime === ViewTimeOption.Current
      "
    >
      <ReturnRulesTable
        v-if="shippingLine && containerType"
        class="w-full"
        :searched-container-number="searchedContainerNumber"
        :existing-single-empty-appointments="existingSingleEmptyAppointments"
      />
      <div
        v-else
        class="flex justify-center items-center h-30vh text-xl text-gray-600"
      >
        👆 Select a Shipping Line and Container Type to view return options 👆
      </div>
    </div>
    <!-- Historical by container -->
    <div
      v-else-if="
        view === ViewOption.ByLineAndType &&
        viewTime === ViewTimeOption.Historical
      "
    >
      TODO
    </div>
    <!-- By Terminal -->
    <div v-else-if="view === ViewOption.ByTerminal">
      <ReturnRulesReadingDisplayWrapper v-if="terminal" :terminal="terminal" />
      <!-- <ReturnRulesTable
        class="w-full"
        :terminal="terminal"
        :shipping-line="undefined"
        :container-type="undefined"
      /> -->
    </div>
  </Card>
</template>

<style lang="scss">
.err-form-controls .el-form-item__label {
  @apply text-sm text-gray-500;
}
.total-appts-count {
  min-width: 28px;
  display: inline-block;
  text-align: right;
}
</style>

<route lang="yaml">
meta:
  auth: true
  layout: default
name: empty-returns
</route>
