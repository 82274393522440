<script lang="ts" setup>
import startCase from 'lodash-es/startCase'
import AppointmentInfo from './AppointmentInfo.vue'
import type { Container } from '~/models/containers'
import { TERMINAL_NOT_REQUIRING_APPOINTMENTS } from '~/constants'
import { ContainerWatchState } from '~/services/apiClient'
import { useShippingLinesStore } from '~/stores/shippingLines'

const props = defineProps<{
  container: Container
}>()
const shippingLineStore = useShippingLinesStore()
onMounted(shippingLineStore.loadShippingLinesCached)
const container = toRef(props, 'container')

const activeTab = ref('')
function pickInitialTab() {
  switch (container.value.cycle_state) {
    case ContainerWatchState.ImportTrackingOnVessel:
      activeTab.value = 'import_tracking'
      break
    case ContainerWatchState.ImportTracking:
      activeTab.value = 'import_tracking'
      break
    case ContainerWatchState.ImportApptBooking:
      activeTab.value = 'appointments'
      break
    case ContainerWatchState.ImportApptBooked:
      activeTab.value = 'appointments'
      break
    case ContainerWatchState.ImportPickedUp:
      activeTab.value = 'gate_transactions'
      break
    case ContainerWatchState.ImportEmptyApptBooked:
      activeTab.value = 'appointments'
      break
    case ContainerWatchState.ImportEmptyReturned:
      activeTab.value = 'gate_transactions'
      break
    case ContainerWatchState.ExportApptBooked:
      activeTab.value = 'appointments'
      break
    default:
      activeTab.value = 'history'
  }
}
onMounted(pickInitialTab)
const containerNumber = computed(() => container.value.number)
watch(containerNumber, pickInitialTab)
const showRawDataChanges = ref(false)
</script>

<template>
  <div class="py-4 px-6">
    <ContainerDetailsHeader :container="container" />
    <!-- Details (tabs) -->
    <el-tabs v-model="activeTab" class="mt-2">
      <!-- Import Status -->
      <el-tab-pane name="import_tracking" lazy>
        <template #label>
          <div>
            <span
              v-tooltip="'Import availability tracking'"
              class="fs-view-import-status"
            >
              <LoadedContainerIcon class="align-middle" /> Availability
            </span>
          </div>
        </template>

        <AutoBookButton
          v-if="container.auto_booking_applies"
          :containers="[container]"
          size="small"
        />
        <ImportStatusInfo
          v-if="container.import_status"
          :key="container.key"
          :container="container"
        />
      </el-tab-pane>

      <!-- Appointments -->
      <el-tab-pane name="appointments" lazy>
        <template #label>
          <span
            v-tooltip="
              'Appointment booking, rescheduling and a list of all appointments'
            "
            class="fs-view-appointments"
          >
            <AppointmentIcon /> Appointments
          </span>
        </template>
        <div
          v-if="
            container.terminal &&
            TERMINAL_NOT_REQUIRING_APPOINTMENTS.has(container.terminal)
          "
          class="text-center font-bold mx-15 text-lg text-gray-700"
        >
          Appointment booking not required! 🎉
        </div>
        <template v-else>
          <BookingSection
            v-if="
              container.auto_booking_applies ||
              container.import_booking_applies ||
              container.has_active_appointment
            "
            :key="container.key"
            :container="container"
          />
          <template v-if="container.latest_appointment">
            <DetailHeader>
              <i-mdi:calendar class="align-middle" height="20" />
              Latest Appointment: #<ClickToCopy
                :text-to-copy="
                  container.latest_appointment.display_terminal_reference
                "
              />
              -
              {{ startCase(container.latest_appointment.status) }}
            </DetailHeader>
            <AppointmentInfo
              v-if="container.latest_appointment"
              :appointment="container.latest_appointment"
            />
          </template>
          <DetailHeader>
            <i-mdi:calendar-multiselect class="align-middle" height="20" />
            All appointments
          </DetailHeader>
          <AppointmentsTable
            :key="container.key"
            :container-number="container.number"
            :after="container.watched_time.minus({ days: 14 })"
          />
        </template>
      </el-tab-pane>

      <!-- Gate Transactions -->
      <el-tab-pane name="gate_transactions" lazy>
        <template #label>
          <span
            v-tooltip="'Equipment Interchange Receipts (Gate Transactions)'"
            class="fs-view-gate-transactions"
          >
            <i-mdi:ticket-outline class="align-middle" />
            EIR
          </span>
        </template>

        <el-alert :closable="false" type="warning" show-icon>
          EIR is a beta feature. We do not find all EIR transactions yet, so
          don't rely on this!
        </el-alert>
        <GateTransactionsTable :container="container" />

        <GateTransactionInfo
          v-if="container.inbound_gate_transaction"
          :gate-transaction="container.inbound_gate_transaction"
        />
        <GateTransactionInfo
          v-if="container.outbound_gate_transaction"
          :gate-transaction="container.outbound_gate_transaction"
        />
      </el-tab-pane>

      <!-- History -->
      <el-tab-pane name="history" lazy>
        <template #label>
          <span
            v-tooltip="'All terminal data changes in one feed'"
            class="fs-view-container-history"
          >
            <i-mdi:history class="align-middle" /> History
          </span>
        </template>
        <el-switch
          v-model="showRawDataChanges"
          active-text="Show raw changes"
          class="mb-4 ml-1"
        />
        <ContainerHistory
          :key="container.key"
          :container-number="container.number"
          :show-raw-data-changes="showRawDataChanges"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
