<!-- This is just for the display elements without any of the data loading
and popover shenanigans. This makes creating stories just for working on display
easier -->
<script lang="ts" setup>
import type { ReturnRule } from '~/models/returnRules'
import { getTerminalLabel } from '~/constants'
import { ReceivingRule, type TerminalName } from '~/services/apiClient'

const props = defineProps<{
  terminal: TerminalName
  returnRule: ReturnRule | null
  // `null` would mean we are loading or won't load or don't have data
  numSingleOpportunities: number | null
  numDualOpportunities: number | null
  numBookedSingles: number | null
  numBookedDuals: number | null
}>()
const tooltip = computed(() => {
  if (!props.returnRule) {
    return ` ${getTerminalLabel(
      props.terminal
    )} has not posted rules for this  date+shift`
  }
})
// Computed properties
const color = computed((): string => {
  if (!props.returnRule) {
    return 'bg-gray-50'
  }
  switch (props.returnRule.rule) {
    case ReceivingRule.Closed:
      return 'bg-red-200'
    case ReceivingRule.Open:
      if (props.numSingleOpportunities === 0) {
        return 'bg-orange-200'
      } else {
        return 'bg-green-200'
      }
    case ReceivingRule.DualsOnly:
      return 'bg-orange-200'
    case ReceivingRule.Exempt:
      return 'bg-blue-200'
  }
})
const showDualsColumn = computed(() => {
  if (props.returnRule?.duals_only) return true
  if (props.numBookedDuals || props.numDualOpportunities) return true
  return false
})
const showSinglesColumn = computed(() => {
  if (props.returnRule?.open) return true
  if (props.numBookedSingles || props.numSingleOpportunities) return true
  return false
})
const showBookedRow = computed(() => {
  if (props.numBookedDuals || props.numBookedSingles) {
    return true
  } else {
    return false
  }
})
const showAvailableRow = computed(() => {
  if (props.numSingleOpportunities) return true
  if (props.returnRule?.open || props.returnRule?.can_dual) return true
  return false
})
const noTableData = computed(() => {
  return !showBookedRow.value && !showAvailableRow.value
})
</script>

<template>
  <td
    v-tooltip="tooltip"
    :class="[color]"
    style="height: 70px"
    class="cursor-pointer py-0 shift-cell"
  >
    <div>
      <!-- Rule -->
      <!-- <div v-if="props.returnRule" class="text-lg text-gray-600 -mt-3px"></div> -->
      <template v-if="!props.returnRule && noTableData">-</template>
      <div
        v-else-if="noTableData && props.returnRule"
        class="return-rule text-center"
      >
        {{ props.returnRule.rule_description }}
      </div>
      <table v-else>
        <thead>
          <tr>
            <th class="return-rule">
              <template v-if="props.returnRule">
                {{ props.returnRule.rule_description }}
              </template>
              <template v-else>-</template>
            </th>
            <th v-if="showSinglesColumn">
              <EmptyReturnIcon />
              <!-- Singles -->
            </th>
            <th v-if="showDualsColumn">
              <DualsIcon />
              <!-- Duals -->
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="showAvailableRow">
            <th class="label">
              <!-- <i-mdi:calendar-plus class="align-text-bottom" /> -->
              Avail
            </th>
            <td v-if="showSinglesColumn" data-testid="avail-single">
              {{ props.numSingleOpportunities }}
            </td>
            <td v-if="showDualsColumn" data-testid="avail-duals">
              {{ props.numDualOpportunities }}
            </td>
          </tr>
          <tr v-if="showBookedRow">
            <th>
              <!-- <i-mdi:calendar-check class="align-text-bottom" /> -->
              Booked
            </th>
            <td v-if="showSinglesColumn" data-testid="booked-single">
              {{ props.numBookedSingles }}
            </td>
            <td v-if="showDualsColumn" data-testid="booked-duals">
              {{ props.numBookedDuals }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </td>
</template>

<style scoped lang="scss">
td.shift-cell {
  @apply mx-2 px-2 py-1 text-center;
  height: 60px;
  width: 100px;
}
table {
  width: 60px;
  td,
  th {
    @apply text-sm text-gray-600 font-normal;
  }
  th {
    @apply font-gray-300;
  }
  > thead th {
    min-width: 30px;
    @apply text-center;
    svg {
      @apply align-text-bottom text-gray-600;
    }
  }
  th:first-child {
    @apply text-right;
  }
  th.return-rule {
    width: 40px;
  }
}
.return-rule {
  @apply text-base;
  font-weight: 500;
}
</style>
