<script lang="ts" setup>
import startCase from 'lodash-es/startCase'
import { formatDTMilitaryWDate } from '~/utils'
import type BookingRequest from '~/models/bookingRequest'
import { ActionStatus } from '~/models/actionRequests'
import { ActionType } from '~/services/apiClient'
import { useBookingRequestsStore } from '~/stores/bookingRequests'

const props = defineProps<{
  bookingRequest: BookingRequest
}>()

const emit = defineEmits<{
  (e: 'viewContainer', containerNumber: string): void
}>()

const bookingRequestsStore = useBookingRequestsStore()

function statusToBadgeType(
  status: ActionStatus
): 'warning' | 'success' | 'danger' | 'info' {
  switch (status) {
    case ActionStatus.InProgress:
      return 'info'
    case ActionStatus.Completed:
      return 'success'
    case ActionStatus.Failed:
      return 'danger'
    case ActionStatus.Rejected:
      return 'warning'
  }
}

function actionToPhrase(action: ActionType): string {
  switch (action) {
    case ActionType.Book:
      return 'Booked'
    case ActionType.Cancel:
      return 'Cancelled'
    case ActionType.Reschedule:
      return 'Rescheduled'
    case ActionType.BookEmpty:
      return 'Empty Booked'
    default:
      return 'Booked'
  }
}

function enteredInTMSTooltip(bookingRequest: BookingRequest): string {
  if (
    bookingRequest.entered_in_tms &&
    bookingRequest.entered_in_tms_time &&
    bookingRequest.entered_in_tms_by
  ) {
    return `Entered by ${bookingRequest.entered_in_tms_by.first_name} ${
      bookingRequest.entered_in_tms_by.last_name
    } on ${formatDTMilitaryWDate(bookingRequest.entered_in_tms_time)}`
  } else {
    return 'For keeping track of appointments entered in your systems'
  }
}
</script>

<template>
  <Card class="py-2 px-4">
    <div class="flex justify-between">
      <div>
        <div class="text-xs text-gray-400">
          <RobotIcon
            v-if="props.bookingRequest.auto_booked"
            v-tooltip="'Requested via auto-book'"
            class="align-text-top mr-1"
          />
          <template v-if="bookingRequest.status === ActionStatus.Completed">
            {{ actionToPhrase(bookingRequest.action_type) }}
          </template>
          <template v-else>Requested</template>
          {{ formatDTMilitaryWDate(bookingRequest.created) }}
          <template v-if="bookingRequest.requested_by">
            by {{ bookingRequest.requested_by.first_name }}
            {{ bookingRequest.requested_by.last_name }}
          </template>
        </div>
        <div>
          <a
            href="#"
            class="font-semibold text-lg underline"
            @click.prevent="
              emit('viewContainer', bookingRequest.container_number)
            "
          >
            {{ bookingRequest.container_number }}
          </a>
          <ClickToCopy
            v-if="bookingRequest.appointment_number"
            :text-to-copy="bookingRequest.appointment_number"
            tooltip-text="Copy appointment number"
            class="ml-3"
            >#{{ bookingRequest.appointment_number }}</ClickToCopy
          >
        </div>
        <span class="font-semibold">
          {{ bookingRequest.terminal.toUpperCase().replace('_', ' ') }}
        </span>
        at
        <span class="font-semibold">{{
          formatDTMilitaryWDate(bookingRequest.window_start)
        }}</span>
      </div>
      <div class="basis-0 ml-4 min-w-130px">
        <ColoredBadge
          :type="statusToBadgeType(bookingRequest.status)"
          size="large"
          class="ml-3 self-center w-full ml-0 text-sm"
          >{{ startCase(bookingRequest.status) }}</ColoredBadge
        >
        <el-checkbox
          v-if="bookingRequest.status === ActionStatus.Completed"
          v-tooltip="enteredInTMSTooltip(bookingRequest)"
          :checked="bookingRequest.entered_in_tms"
          label="Entered in TMS"
          border
          class="mt-2"
          size="small"
          @change="
            bookingRequestsStore.markEnteredInTMS({
              entered_in_tms: $event as boolean,
              actionRequestID: bookingRequest.id,
              actionType: bookingRequest.action_type,
            })
          "
        />
      </div>
    </div>
    <div v-if="bookingRequest.error_message" class="text-gray-600 text-sm mt-1">
      <span
        v-if="bookingRequest.status === ActionStatus.Rejected"
        class="font-bold text-orange-400"
      >
        Message:
      </span>
      <span v-else class="font-bold text-red-800"> Error message: </span>
      {{ bookingRequest.error_message }}
    </div>
  </Card>
</template>
