<script lang="ts" setup>
import startCase from 'lodash-es/startCase'
import type { PropType } from 'vue'
import type { Container } from '~/models/containers'
const props = defineProps({
  container: { type: Object as PropType<Container>, required: true },
})
const selectedImportSource = ref(
  props.container.import_statuses.length > 0
    ? props.container.import_statuses[0].key
    : null
)
</script>

<template>
  <el-tabs
    v-if="selectedImportSource != null"
    v-model="selectedImportSource"
    class="import-status-details"
  >
    <el-tab-pane
      v-for="importStatus in props.container.import_statuses"
      :key="importStatus.source"
      :name="importStatus.key"
      :label="importStatus.sourceLabel"
    >
      <template #label>
        {{ importStatus.sourceLabel }}
        <ColoredBadge
          v-if="importStatus.isStale"
          v-tooltip="importStatus.staleDescription"
          type="danger"
          >Stale Data</ColoredBadge
        >
      </template>
      <div class="mb-1">
        <ObjectChangeTimesDisplay :object="importStatus" />
        <RefreshImportStatusButton :import-status="importStatus" class="ml-2" />
      </div>
      <el-descriptions border class="flex-1" :column="1">
        <el-descriptions-item label="Location">
          {{ importStatus.location_description }}
        </el-descriptions-item>
        <el-descriptions-item label="Raw Terminal Location">
          {{ importStatus.location ?? 'N/A' }}
          <template v-if="importStatus.terminal_block">
            - {{ importStatus.terminal_block }}
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="Holds">
          {{ importStatus.holds.join(', ') }}
        </el-descriptions-item>
        <el-descriptions-item label="Last Free Day">
          <LFDDisplay
            :last-free-date="importStatus.last_free_day"
            :info="importStatus.lfdInfo"
          />
        </el-descriptions-item>
        <el-descriptions-item label="Demurrage Fees">
          <template v-if="importStatus.demurrage_fees">
            ${{ importStatus.demurrage_fees.toLocaleString() }}
          </template>
          <template v-else> N/A </template>
        </el-descriptions-item>
        <el-descriptions-item label="Shipping Line">
          {{ importStatus.shipping_line }}
        </el-descriptions-item>
        <el-descriptions-item label="Container Type">
          {{ importStatus.container_type ?? 'N/A' }}
        </el-descriptions-item>
        <el-descriptions-item
          v-if="importStatus.master_bill_of_lading"
          label="Master Bill of Lading"
        >
          {{ importStatus.master_bill_of_lading }}
        </el-descriptions-item>
        <el-descriptions-item v-if="importStatus.wheeled" label="Wheeled">
          {{ importStatus.wheeled }}
        </el-descriptions-item>
        <el-descriptions-item
          v-if="importStatus.closed_area"
          label="Closed Area"
        >
          {{ importStatus.closed_area }}
        </el-descriptions-item>
        <el-descriptions-item
          v-if="importStatus.discharged_time"
          label="Discharged Time"
        >
          {{
            importStatus.discharged_time
              .toLocal()
              .toFormat('yyyy-MM-dd HH:mm:ss')
          }}
        </el-descriptions-item>
        <template v-if="importStatus.extra_data">
          <el-descriptions-item
            v-for="(value, fieldName) in importStatus.extra_data"
            :key="fieldName"
            :label="startCase(fieldName.toLowerCase().replace('_', ' '))"
          >
            {{ value ?? 'N/A' }}
          </el-descriptions-item>
        </template>
      </el-descriptions>
    </el-tab-pane>
  </el-tabs>
</template>

<style lang="scss">
.import-status-details .el-descriptions__content {
  word-break: break-all;
}
</style>
