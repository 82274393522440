<script lang="ts" setup>
import type { PropType } from 'vue'
import { ref } from 'vue'
import { useCustomerStore } from '~/stores/customers'

const props = defineProps({
  modelValue: { type: Number as PropType<number | undefined> },
  allowCreate: { type: Boolean, default: false },
})
const emit = defineEmits<{
  (e: 'update:modelValue', value: number | undefined): void
}>()
const customerStore = useCustomerStore()
const loading = ref(false)

const selectedCustomerID = useVModel(props, 'modelValue', emit)
function loadCustomerOptions(q?: string) {
  customerStore.load(q || undefined)
}
onMounted(loadCustomerOptions)
</script>

<template>
  <el-select
    v-bind="$attrs"
    :model-value="selectedCustomerID"
    filterable
    remote
    reserve-keyword
    placeholder="Customer"
    suffix-icon="el-icon-search"
    :remote-method="loadCustomerOptions"
    :loading="loading"
    clearable
    size="large"
    @focus="() => loadCustomerOptions()"
    @update:model-value="emit('update:modelValue', $event || undefined)"
  >
    <template #prefix>
      <i-mdi:handshake-outline />
    </template>
    <el-option
      v-for="customer in customerStore.customers"
      :key="customer.id"
      :label="customer.name"
      :value="customer.id"
    />
  </el-select>
</template>
