import { defineStore } from 'pinia'
import { useUserStore } from './user'
import BookingRequest from '~/models/bookingRequest'
import type { ActionType } from '~/services/apiClient'
import { AppointmentBookingApi } from '~/services/apiClient'

export const useBookingRequestsStore = defineStore('bookingRequests', () => {
  const bookingRequests = ref<BookingRequest[]>([])
  const loading = ref(false)
  const abortController = ref<AbortController | null>(null)

  function load({ containerSearchText }: { containerSearchText?: string }) {
    loading.value = true
    bookingRequests.value = []

    if (abortController.value) {
      abortController.value.abort()
    }
    const newAbortController = new AbortController()
    abortController.value = newAbortController

    const userStore = useUserStore()
    const api = new AppointmentBookingApi()
    api
      .getBookingHistoryAppointmentsBookingHistoryGet(containerSearchText, {
        signal: abortController.value.signal,
      })
      .then((resp) => {
        bookingRequests.value = resp.data.map(
          (b) => new BookingRequest(b, userStore.demo_mode)
        )
      })
      .finally(() => {
        if (newAbortController === abortController.value) {
          loading.value = false
          abortController.value = null
        }
      })
  }

  function markEnteredInTMS({
    entered_in_tms,
    actionRequestID,
    actionType,
  }: {
    entered_in_tms: boolean
    actionRequestID: number
    actionType: ActionType
  }) {
    const api = new AppointmentBookingApi()
    api.patchBookingHistoryAppointmentsBookingHistoryBookingRequestIdPatch(
      actionRequestID,
      { entered_in_tms },
      actionType
    )
  }

  return {
    bookingRequests,
    loading,
    load,
    markEnteredInTMS,
  }
})
