<script setup lang="ts">
import { useRouter } from 'vue-router'
import { UsersApi } from '~/services/apiClient'
import { useCurrentOrgStore } from '~/stores/currentOrg'
import { useUserStore } from '~/stores/user'

useHead({
  title: 'Dray Dog - Login',
})

const userStore = useUserStore()
const { selectOrgFromURL } = useCurrentOrgStore()
const usersApi = new UsersApi()
const form = reactive({
  email: '',
  password: '',
})
const router = useRouter()
function goToContainersPage() {
  router.push({ name: 'containers' })
}
onMounted(() => {
  if (userStore.loggedIn) {
    goToContainersPage()
  }
})

const loggingIn = ref(false)
async function onLogin() {
  loggingIn.value = true
  let resp
  try {
    resp = await usersApi.getTokenUsersTokenPost({
      email: form.email,
      password: form.password,
    })
  } finally {
    loggingIn.value = false
  }
  userStore.login(resp.data)
  await selectOrgFromURL()
  goToContainersPage()
}
</script>

<template>
  <CenteredCard>
    <template #title>
      <div
        style="font-family: 'Russo One', Roboto, sans-serif; font-size: 30px"
        class="-mb-2"
      >
        <img
          src="../assets/logo_outline.svg"
          alt=""
          style="height: 30px; margin-top: -5px"
          class="mb-1 inline-block"
        />
        Login
      </div>
    </template>
    <el-form v-model="form" label-width="84px">
      <el-form-item label="Email:">
        <el-input v-model="form.email" name="email"></el-input>
      </el-form-item>
      <el-form-item label="Password:">
        <el-input
          v-model="form.password"
          type="password"
          name="password"
          show-password
        ></el-input>
      </el-form-item>
      <el-button
        :loading="loggingIn"
        type="primary"
        class="w-full"
        @click="onLogin"
      >
        Login
      </el-button>
    </el-form>
    <div class="text-center mt-2">
      <router-link
        :to="{ name: 'trigger_reset_password', query: { email: form.email } }"
        class="underline cursor-pointer text-xs text-gray-500"
      >
        Reset password
      </router-link>
    </div>
  </CenteredCard>
</template>

<route lang="yaml">
meta:
  layout: no_sidebar
name: login
</route>
